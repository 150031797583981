import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { UserService } from "../../../../core/http/global/user/user.service";
import { ToasterService } from "angular2-toaster";
import { appToaster } from "../../../../configs/app-toaster.config";
import { LogValidationErrorsService } from "../../../../core/services/log-validation-errors.service";
import { AuthenticationService } from "../../../../core/authentication/authentication.service";

@Component ({
	selector    : 'app-reset-password-user',
	templateUrl : './reset-password-user.component.html',
	styleUrls   : ['./reset-password-user.component.css']
})
export class ResetPasswordUserComponent implements OnInit {
	
	formErrors = {
		'password'        : '',
		'confirmPassword' : ''
	};
	
	validationMessages = {
		'password'        : {
			'required'  : 'Password  is required.',
			'minlength' : 'Password at less than 4 characters.',
			'maxlength' : 'Password  must be less than 20 characters.',
		},
		'confirmPassword' : {
			'required'  : 'ConfirmPassword  is required.',
			'minlength' : 'ConfirmPassword at least 4 characters.',
			'maxlength' : 'ConfirmPassword  must be less than 20 characters.',
		}
	};
	
	loadingSave: boolean = false;
	
	passwordForm: FormGroup;
	
	constructor (private fb: FormBuilder,
	             private userService: UserService,
	             private toasterService: ToasterService,
	             private authenticationService: AuthenticationService,
	             private logValidationErrorsService: LogValidationErrorsService) {
	}
	
	ngOnInit () {
		this.createPasswordForm ();
	}
	
	createPasswordForm () {
		this.passwordForm = this.fb.group ({
			'password'        : [
				'',
				[
					Validators.required,
					Validators.minLength (4),
					Validators.maxLength (20),
				]
			],
			'confirmPassword' : [
				'',
				[
					Validators.required,
					Validators.minLength (4),
					Validators.maxLength (20),
				]
			],
		});
		
		this.passwordForm.valueChanges.subscribe ((data) => {
			this.logValidationErrorsService.logValidationErrors (this.passwordForm, this.formErrors, this.validationMessages);
		});
	}
	
	onSubmit () {
		if (!this.passwordForm.valid) {
			return this.logValidationErrorsService.logValidationErrors (this.passwordForm, this.formErrors, this.validationMessages);
		}
		if (this.passwordForm.get ('password').value != this.passwordForm.get ('confirmPassword').value) {
			return this.toasterService.pop ('error', appToaster.errorHead, "Nhập lại mật khẩu không đúng");
		}
		let password       = this.passwordForm.get ('password').value;
		let id             = this.authenticationService.getUserId ();
		this.loadingSave = true;
		
		this.userService.updatePasswordUser (id, password)
			.subscribe ((res) => {
				this.loadingSave = false;
				if (res.status === 'success') {
					this.passwordForm.reset ();
					this.toasterService.pop ('success', appToaster.successHead, res.message);
				} else {
					return this.toasterService.pop ('error', appToaster.errorHead, res.message);
				}
			}, (error) => {
				this.loadingSave = false;
			});
	}
	
}
