import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { PageService } from "../../../core/http/agent/page/page.service";
import { appToaster } from "../../../configs/app-toaster.config";
import { ToasterService } from "angular2-toaster";
import { AuthenticationService } from "../../../core/authentication/authentication.service";
import { AgentService } from "../../../core/http/agent/agent/agent.service";
import { SafeHtmlPipe } from "../../pipes";

const popupKey = 'popup';

@Component ({
	selector    : 'app-popup-page',
	templateUrl : './popup-page.component.html',
	styleUrls   : ['./popup-page.component.css'],
	providers   : [SafeHtmlPipe]
})
export class PopupPageComponent implements OnInit {
	
	
	agentId: number;
	@Input () listPopupDisplay: any = {};
	
	title: string = '';
	content: any  = '';
	
	constructor (private pageService: PageService,
	             private agentService: AgentService,
	             private activeModal: NgbActiveModal,
	             private toasterService: ToasterService,
	             private safeHtmlPipe: SafeHtmlPipe,
	             private authenticationService: AuthenticationService) {
	}
	
	ngOnInit () {
		this.agentId = this.authenticationService.getAgentId ();
		this.getItemDisplay (this.listPopupDisplay);
	}
	
	
	getItemDisplay (listPopup: any) {
		let listPopupLocal = this.authenticationService.getData (popupKey);
		if (!listPopupLocal) {
			let popupDisplay                   = listPopup.shift ();
			let dataPopupLocal                 = {};
			dataPopupLocal[popupDisplay['id']] = {'time' : new Date ().getTime (), 'numberShow' : 1};
			this.authenticationService.setData (popupKey, JSON.stringify (dataPopupLocal));
			this.setDataPopup (popupDisplay);
		} else {
			listPopupLocal = JSON.parse (listPopupLocal);
			for (let i = 0; i < listPopup.length; i++) {
				let popup   = listPopup[i];
				let popupId = popup['id'];
				
				if (!listPopupLocal[popupId]) {
					let popupLocal      = {};
					popupLocal[popupId] = {'time' : new Date ().getTime (), 'numberShow' : 1};
					
					Object.assign (listPopupLocal, popupLocal);
					
					this.authenticationService.setData (popupKey, JSON.stringify (listPopupLocal));
					this.setDataPopup (popup);
					break;
					
				} else {
					let timePopupLocal = new Date (listPopupLocal[popupId]['time']);
					let timeNow        = new Date ();
					
					if (timeNow.getDate () != timePopupLocal.getDate () || timeNow.getMonth () != timePopupLocal.getMonth () || timeNow.getFullYear () != timePopupLocal.getFullYear ()) {
						let popupLocal      = {};
						popupLocal[popupId] = {'time' : new Date ().getTime (), 'numberShow' : 1};
						
						Object.assign (listPopupLocal, popupLocal);
						
						this.authenticationService.setData (popupKey, JSON.stringify (listPopupLocal));
						this.setDataPopup (popup);
						break;
					} else {
						let numberShow = listPopupLocal[popupId]['numberShow'];
						let totalShow  = listPopup[i]['number_show'];
						if (numberShow < totalShow) {
							numberShow++;
							let popupDisplay               = listPopup[i];
							let popupLocal                 = {};
							popupLocal[popupDisplay['id']] = {'time' : new Date ().getTime (), 'numberShow' : numberShow};
							
							Object.assign (listPopupLocal, popupLocal);
							
							this.authenticationService.setData (popupKey, JSON.stringify (listPopupLocal));
							this.setDataPopup (popupDisplay);
							break;
						}
					}
				}
				if (i == listPopup.length - 1) {
					this.activeModal.close (false);
				}
			}
		}
	}
	
	setDataPopup (popup: any) {
		this.title    = popup.title;
		this.content  = popup.content;
		let listParam = JSON.parse (popup.param);
		let params    = {};
		
		Object.keys (listParam).forEach ((key) => {
			if (listParam[key]['page'] == -1) {
				params[key] = listParam[key];
			} else {
				this.getPageDetail (listParam[key]['page'], listParam[key]['key'])
			}
		});
		
		this.getInfoAgent (params);
	}
	
	getPageDetail (id, key) {
		this.pageService.getDetailPage (id)
			.subscribe ((res) => {
				if (res.status == 'success') {
					let keyReplace = new RegExp ('{{' + key + '}}', 'g');
					this.content   = this.content.replace (keyReplace, res.data.content);
				}
			});
	}
	
	getInfoAgent (params) {
		if (Object.keys (params).length) {
			this.agentService.getInfoAgent (this.agentId)
				.subscribe ((res) => {
					if (res.status == 'success') {
						let agent = res.data;
						this.setDataParams (params, agent);
					} else {
						this.toasterService.pop ('error', appToaster.errorHead, res.message);
					}
				}, (err) => {
					this.toasterService.pop ('error', appToaster.errorHead, err);
				});
		}
	}
	
	setDataParams (params, agent: any) {
		if (params['agent_code']) {
			let keyReplace = new RegExp ('{{agent_code}}', 'g');
			this.content   = this.content.replace (keyReplace, agent.agent_code);
		}
		
		if (params['name']) {
			let keyReplace = new RegExp ('{{name}}', 'g');
			this.content   = this.content.replace (keyReplace, agent.name);
		}
		
		if (params['email']) {
			let keyReplace = new RegExp ('{{email}}', 'g');
			this.content   = this.content.replace (keyReplace, agent.email);
		}
		
		if (params['phone']) {
			let keyReplace = new RegExp ('{{phone}}', 'g');
			this.content   = this.content.replace (keyReplace, agent.phone);
		}
		
		if (params['address']) {
			let keyReplace = new RegExp ('{{address}}', 'g');
			this.content   = this.content.replace (keyReplace, agent.address);
		}
		
		if (params['facebook']) {
			let keyReplace = new RegExp ('{{facebook}}', 'g');
			this.content   = this.content.replace (keyReplace, agent.facebook);
		}
		
		if (params['group']) {
			this.agentService.getAllGroup ()
				.subscribe ((res) => {
					if (res.status == 'success') {
						let listGroup  = res.data;
						let keyReplace = new RegExp ('{{group}}', 'g');
						this.content   = this.content.replace (keyReplace, listGroup[agent.group]['name']);
					}
				}, (err) => {
					this.toasterService.pop ('error', appToaster.errorHead, err);
				});
		}
	}
	
	close (): void {
		this.activeModal.close (false);
	}
	
	getContent () {
		return this.safeHtmlPipe.transform (this.content);
	}
	
}
