import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl } from "@angular/forms";

@Component({
  selector: 'app-card-name-select',
  templateUrl: './card-name-select.component.html',
  styleUrls: ['./card-name-select.component.css']
})
export class CardNameSelectComponent implements OnInit {
	
	@Input ()
	placeholder: string = 'Chủ tài khoản';
	
	@Input ()
	public parentForm: FormControl;
	
	@Output ()
	selectItemChange = new EventEmitter<any> ();
	
	constructor () {
	
	}
	
	ngOnInit () {
	
	}
	
	changed(text: string) {
		this.selectItemChange.emit (event);
	}

}
