import { Component, OnInit } from '@angular/core';
import { appToaster } from "../../../configs/app-toaster.config";
import { ToasterService } from "angular2-toaster";
import { Router } from "@angular/router";

import { NotificationService } from "../../../core/http/agent/notification/notification.service";
import { AuthenticationService } from "../../../core/authentication/authentication.service";
import { TimeSinceService } from "../../../core/services/time-since.service";

import { HistorySearchModel, IS_AGENT_ADMIN, IS_NOT_AGENT_ADMIN } from "../../models/agent/history-search.model";

@Component ({
	selector    : 'app-popup-notification',
	templateUrl : './popup-notification.component.html',
	styleUrls   : ['./popup-notification.component.css']
})
export class PopupNotificationComponent implements OnInit {
	
	currentPage: number  = 1;
	total: number        = 0;
	totalNotSeen: number = 0;
	startShow: number    = 0;
	endShow: number      = 0;
	perPage: number      = 5;
	
	listLogsAction: any = {};
	
	isAgentAdmin: boolean = false;
	
	constructor (private router: Router,
	             private toasterService: ToasterService,
	             private timeSinceService: TimeSinceService,
	             private notificationService: NotificationService,
	             private authenticationService: AuthenticationService) {
	}
	
	ngOnInit () {
		this.isAgentAdmin = this.authenticationService.isAgentAdmin ();
		this.getPopupLogsAction ();
	}
	
	getPopupLogsAction () {
		let historySearchModel: HistorySearchModel = new HistorySearchModel ();
		historySearchModel.page                    = this.currentPage;
		historySearchModel.limit                   = this.perPage;
		if (this.isAgentAdmin) {
			historySearchModel.agentAdmin = IS_AGENT_ADMIN;
		} else {
			historySearchModel.agentAdmin = IS_NOT_AGENT_ADMIN;
		}
		// this.notificationService.getListLogsAction (historySearchModel)
		// 	.subscribe ((res) => {
		// 		if (res.status === 'success') {
		// 			this.listLogsAction = res.data.logsAction;
		// 			this.totalNotSeen   = res.data.totalNotSeen;
		// 		} else {
		// 			this.toasterService.pop ('error', appToaster.errorHead, res.message);
		// 		}
		// 	}, (error) => {
		// 		this.toasterService.pop ('error', appToaster.errorHead, error.message);
		// 	});
	}
	
	timeDifference (previous) {
		return this.timeSinceService.timeSince (previous);
	}
	
	seeMore () {
		this.notificationService.updateAllStatusNotification (this.isAgentAdmin)
			.subscribe ((res) => {
				if (res.status === 'success') {
					this.totalNotSeen = 0;
					this.getPopupLogsAction ();
				} else {
					this.toasterService.pop ('error', appToaster.errorHead, res.message);
				}
			}, (error) => {
				this.toasterService.pop ('error', appToaster.errorHead, error.message);
			});
		
		this.router.navigate (['/auth/profile', {type : 'view-history'}]);
	}
	
	updateStatus (id: any) {
		this.notificationService.updateStatusNotification (id)
			.subscribe ((res) => {
				if (res.status === 'success') {
					this.getPopupLogsAction ();
				} else {
					this.toasterService.pop ('error', appToaster.errorHead, res.message);
				}
			}, (error) => {
				this.toasterService.pop ('error', appToaster.errorHead, error.message);
			});
	}
}
