import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl } from "@angular/forms";
import { BankService } from "../../../core/http/agent/bank/bank.service";

@Component ({
	selector    : 'app-bank-select',
	templateUrl : './bank-select.component.html',
	styleUrls   : ['./bank-select.component.css']
})
export class BankSelectComponent implements OnInit {
	
	
	@Input ('parentForm')
	public parentForm: FormControl;
	
	@Input () multiple: boolean = false;
	
	@Input () clearable: boolean = true;
	
	@Input ()
	classSelect: string = '';
	
	bankListValue: any;
	@Output ()
	bankListChange = new EventEmitter<any> ();
	
	@Input ()
	get bankList () {
		return this.bankListValue;
	}
	
	set bankList (val) {
		this.bankListValue = val;
		this.getBankName(val);
		this.bankListChange.emit (this.bankListValue);
	}
	
	bankName: any = [];
	
	@Output ()
	selectItemChange = new EventEmitter<string> ();
	
	constructor (private bankService: BankService) {
	
	}
	
	ngOnInit () {
		if (Object.keys (this.bankList).length == 0) {
			this.getBankList ();
		}
	}
	
	getBankList () {
		this.bankService.getAllBank ()
			.subscribe (res => {
				if (res.status === 'success') {
					this.bankList = res.data;
					this.getBankName(this.bankList);
				} else {
					this.bankList = {};
					this.getBankName(this.bankList);
				}
			});
	}
	
	getBankName (bankList) {
		let bankName = [];
		if(bankList && Object.keys (bankList).length > 0){
			Object.keys (bankList).forEach ((key) => {
				bankName.push ({
					id   : bankList[key]['id'],
					name : bankList[key]['name']
				});
			});
		}
		this.bankName = bankName;
	}
	
	selectItem (event) {
		this.selectItemChange.emit (event);
	}
}
