export class FolderModel {
  public id: number;
  public name: string;
  public folder_id: number;
  public path: string;
  public format: string;
  public is_public: boolean;
  public time_created: number;
  public time_updated: number;
}
