import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AuthenticationService } from '../../authentication/authentication.service';
import { DEFAULT_LANGUAGE } from '../../../shared/models/base.model';

@Component ({
	selector    : 'app-layout-site',
	templateUrl : './layout-site.component.html',
	styleUrls   : ['./layout-site.component.scss']
})
export class LayoutSiteComponent implements OnInit {
	
	viewKey: number = 0;
	
	constructor (
		private translate: TranslateService,
		private authenticationService: AuthenticationService
	) {
		translate.setDefaultLang (this.authenticationService.getData ('language') || DEFAULT_LANGUAGE);
		translate.use (this.translate.getDefaultLang ());
	}
	
	ngOnInit () {
	}
	
	viewKeyChange (event) {
		this.viewKey = event;
	}
}
