import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TextMaskModule } from 'angular2-text-mask';
import { FileUploadModule } from 'ng2-file-upload';
import { RouterModule } from "@angular/router";
import { SelectLanguageComponent } from './layout/select-language/select-language.component';
import { LangTranslateModule } from '../modules/lang-translate/lang-translate.module';

import {
	NbActionsModule,
	NbCardModule,
	NbLayoutModule,
	NbMenuModule,
	NbRouteTabsetModule,
	NbSearchModule,
	NbSidebarModule,
	NbTabsetModule,
	NbThemeModule,
	NbUserModule,
	NbCheckboxModule,
	NbPopoverModule,
	NbContextMenuModule,
} from '@nebular/theme';

import { SharedModule } from "../shared/module/shared.module";

import { HeaderComponent } from './layout/header/header.component';
import { FooterComponent } from './layout/footer/footer.component';
import { NavComponent } from './layout/nav/nav.component';
import { LayoutComponent } from './layout/layout/layout.component';
import { LayoutSiteComponent } from './layout/layout-site/layout-site.component';

import { CapitalizePipe, PluralPipe, RoundPipe, TimingPipe, SafeHtmlPipe } from '../shared/pipes';
import { NgSelectModule } from '@ng-select/ng-select';

const BASE_MODULES = [CommonModule, FormsModule, ReactiveFormsModule, RouterModule, LangTranslateModule];

const NB_MODULES = [
	NbCardModule,
	NbLayoutModule,
	NbTabsetModule,
	NbRouteTabsetModule,
	NbMenuModule,
	NbUserModule,
	NbActionsModule,
	NbSearchModule,
	NbSidebarModule,
	NbCheckboxModule,
	NbPopoverModule,
	NbContextMenuModule,
	NgbModule,
	TextMaskModule,
	FileUploadModule,
	SharedModule,
];

const COMPONENTS = [
	HeaderComponent,
	FooterComponent,
	NavComponent,
	LayoutComponent,
	LayoutSiteComponent,
	SelectLanguageComponent
];

const PIPES = [
	CapitalizePipe,
	PluralPipe,
	RoundPipe,
	TimingPipe,
    SafeHtmlPipe,
];

const NB_THEME_PROVIDERS = [
	...NbThemeModule.forRoot (
		{
			name : 'default',
		},
		[],
	).providers,
	...NbSidebarModule.forRoot ().providers,
	...NbMenuModule.forRoot ().providers,
];

@NgModule ({
	imports : [...BASE_MODULES, ...NB_MODULES, NgSelectModule],
	exports      : [...BASE_MODULES, ...NB_MODULES, ...COMPONENTS, ...PIPES],
	declarations : [...COMPONENTS, ...PIPES]
})
export class ThemeModule {
	static forRoot (): ModuleWithProviders {
		return <ModuleWithProviders>{
			ngModule  : ThemeModule,
			providers : [...NB_THEME_PROVIDERS],
		};
	}
}
