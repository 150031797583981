import { NgModule } from '@angular/core';
import { RouterModule, Routes, PreloadAllModules } from '@angular/router';

const app_routes: Routes = [
	{path : 'auth', loadChildren : './modules/auth/auth.module#AuthModule'},
	{path : 'agent', loadChildren : './modules/agent/agent.module#AgentModule'},
	{path : '', pathMatch : 'full', redirectTo : '/auth/login'},
	{path : '**', redirectTo : '/auth/404'},
];

@NgModule ({
	imports : [RouterModule.forRoot (app_routes, {preloadingStrategy : PreloadAllModules})],
	exports : [RouterModule]
})
export class AppRoutingModule {
}
