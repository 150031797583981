import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, CanActivateChild, Params, ActivatedRoute } from '@angular/router';

import { AuthenticationService } from '../authentication/authentication.service';
import { appToaster } from '../../configs/app-toaster.config';
import { ToasterService } from 'angular2-toaster';


@Injectable ()
export class ClientAuthGuard implements CanActivate, CanActivateChild {
	
	constructor (
		private router: Router,
		private activatedRoute: ActivatedRoute,
		private toasterService: ToasterService,
		private authenticationService: AuthenticationService) {
	}
	
	canActivate (route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
		return this.chekUser (route, state);
		
	}
	
	canActivateChild (route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
		return this.chekUser (route, state);
	}
	
	private chekUser (route, state): boolean {
		const userType = this.authenticationService.getUserType ();
		const isLogin  = this.authenticationService.isLogin ();
		
		
		if (userType === 'client' && isLogin) {
			let check = this.checkRouter (route);
			if (check) {
				return true;
			} else {
				this.toasterService.pop ('error', appToaster.errorHead, 'Unauthorized: Access is denied');
				this.router.navigateByUrl ('/auth/403');
				return false;
			}
		} else if (isLogin) {
			this.toasterService.pop ('error', appToaster.errorHead, 'Unauthorized: Access is denied');
			this.router.navigateByUrl ('/auth/403');
			return false;
		} else {
			this.router.navigate (['/auth/login'], {queryParams : {returnUrl : state.url}});
			return false;
		}
	}
	
	private checkRouter (route: any) {
		const routerAndView = this.authenticationService.getRouterAndView ();
		const viewKey       = this.authenticationService.getView ();
		let check           = false;
		Object.keys (routerAndView).forEach ((key) => {
			Object.keys (route.url).forEach ((keyRoute) => {
				let listKey = key.split('/');
				Object.keys (listKey).forEach ((data) => {
					if (listKey[data] == route.url[keyRoute]['path']) {
						if (routerAndView[key] != viewKey) {
							this.authenticationService.setViewInfo (routerAndView[key]);
						}
						check = true;
					}
				});
			});
		});
		
		return check;
	}
}

