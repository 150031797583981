import { UserService } from '../../../core/http/global/user/user.service';
import {Component, Inject, LOCALE_ID, OnInit} from '@angular/core';
import { FormBuilder, Validators, FormArray, FormGroup } from '@angular/forms';
import { appSettings } from '../../../configs/app-settings.config';
import { appToaster } from '../../../configs/app-toaster.config';
import { ToasterService } from 'angular2-toaster';
import { Router } from '@angular/router';
import {LANGUAGE_EN, LANGUAGE_VN, LOCALE_VN} from '../../../shared/models/auth/login.model';
import {TranslateService} from '@ngx-translate/core';
import {AuthenticationService} from '../../../core/authentication/authentication.service';

@Component ({
  selector    : 'app-forgot-password',
  templateUrl : './forgot-password.component.html',
  styleUrls   : ['./forgot-password.component.css']
})
export class ForgotPasswordComponent implements OnInit {
  forgotPasswordForm: FormGroup;
  appSettings: any;
  isSubmit: boolean;
  isSuccess: boolean;
  message: string;

  readonly LANGUAGE_VN = LANGUAGE_VN;
  readonly LANGUAGE_EN = LANGUAGE_EN;
  language: any;

  constructor (
    private fb: FormBuilder,
    private userService: UserService,
    private toasterService: ToasterService,
    private router: Router,
    @Inject(LOCALE_ID) protected localeId: string,
    private translate: TranslateService,
    private authenticationService: AuthenticationService
  ) {
    if (!this.authenticationService.getData("language")) {
      if (localeId == LOCALE_VN) {
        this.translate.use (LANGUAGE_VN);
        this.authenticationService.setData("language", LANGUAGE_VN)
      } else {
        this.translate.use (LANGUAGE_EN);
        this.authenticationService.setData("language", LANGUAGE_EN)
      }
    } else {
      this.translate.use(this.authenticationService.getData('language'))
    }
    this.language = this.authenticationService.getData("language")
  }


  ngOnInit () {
    this.isSubmit           = false;
    this.appSettings        = appSettings;
    this.forgotPasswordForm = this.fb.group ({
      email : ['', [Validators.required, Validators.maxLength (250), Validators.email]],
    });
  }

  onSubmit (): boolean {
    if (this.forgotPasswordForm.invalid) {
      this.message = 'Chưa nhập email';
      this.toasterService.pop ('error', appToaster.errorHead, this.message);
      return false;
    } else {
      this.isSubmit = true;
      this.userService.getEmailRecovery (this.forgotPasswordForm.get('email').value)
        .subscribe ((res) => {
          switch (res.status) {
            case 'success':
              this.isSuccess = true;
              this.message   = "Kiểm tra Email để khôi phục mật khẩu.";
              break;
            case 'fail':
              this.isSuccess = false;
              this.message   = "Email không tồn tại hoặc bị khóa.";
              break;
          }
        }, (error) => {
          this.isSuccess = false;
          this.message   = "Có lỗi xảy ra.";
        });
    }
  }

  changeLanguage(language) {
    this.translate.use (language);
    this.authenticationService.setData("language", language);
    window.location.reload()
  }
}
