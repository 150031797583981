import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl } from "@angular/forms";
import { PackageProductService } from "../../../core/http/agent/package-product/package-product.service";

@Component ({
	selector    : 'app-serial-select',
	templateUrl : './serial-select.component.html',
	styleUrls   : ['./serial-select.component.css']
})
export class SerialSelectComponent implements OnInit {
	
	@Input ('parentForm')
	public parentForm: FormControl;
	
	@Input () agentId: number;
	
	typeUpdateValue: boolean = true;
	@Output ()
	typeUpdateChange         = new EventEmitter<any> ();
	
	@Input ()
	get typeUpdate () {
		return this.typeUpdateValue;
	}
	
	set typeUpdate (val) {
		this.typeUpdateValue = val;
		this.typeUpdateChange.emit (this.typeUpdateValue);
	}
	
	productIdValue: string;
	
	@Input ()
	get productId () {
		return this.productIdValue;
	}
	
	set productId (val) {
		if (this.typeUpdate) {
			this.parentForm.setValue (null);
		} else {
			this.typeUpdateValue = true;
		}
		this.getListSerial (val, this.agentId);
		this.productIdValue = val;
	}
	
	@Input () multiple: boolean = false;
	
	@Input ()
	classSelect: string = '';
	
	serialListValue: any;
	@Output ()
	serialListChange = new EventEmitter<string> ();
	
	@Input ()
	get serialList () {
		return this.serialListValue;
	}
	
	set serialList (val) {
		this.serialListValue = val;
		this.serialListChange.emit (Object.assign({}, this.serialListValue));
	}
	
	@Output ()
	selectItemChange = new EventEmitter<string> ();
	
	listSerialName: any = [];
	
	constructor (private packageProductService: PackageProductService) {
	
	}
	
	ngOnInit () {
	}
	
	getListSerial (productId, agentId) {
		if (productId) {
			this.packageProductService.getListSerialByProductId (productId, agentId)
				.subscribe (
					res => {
						if (res.status === 'success') {
							this.serialList = res.data;
							this.getSerial(this.serialList);
						} else {
							this.serialList = {};
							this.getSerial(this.serialList);
							
						}
					}, (error) => {
						this.serialList = {};
						this.getSerial(this.serialList);
					})
		} else {
			this.serialList = {};
			this.getSerial(this.serialList);
		}
	}
	
	getSerial(serialList) {
		let serial = [];
		if (serialList && Object.keys (serialList).length > 0) {
			Object.keys (serialList).forEach ((key) => {
				serial.push ({
					id   : serialList[key]['serial'],
					name : serialList[key]['serial']
				});
			});
		}
		this.listSerialName = serial;
	}
	
	selectItem (event) {
		this.selectItemChange.emit (event);
	}
}

