import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { FortgotPassword } from '../../../../shared/models/auth/fortgot-password';
import { ResetPassword } from '../../../../shared/models/auth/reset-password';
import { Attachments } from '../../../../shared/models/auth/attachments.model';
import { User } from '../../../../shared/models/auth/user.model';
import { environment } from "../../../../../environments/environment";

@Injectable ({
	providedIn : 'root'
})
export class UserService {
	
	constructor (private http: HttpClient) {
	}
	
	updatePasswordUser (id: number, password: string): Observable<any> {
		const href = environment.host_auth + 'update-password-user';
		return this.http.post<any> (href, {id : id, password : password});
	}
	
	updateStatusUser (id: number, status: number): Observable<any> {
		const href = environment.host_auth + 'update-status-user';
		return this.http.post<any> (href, {id : id, status : status});
	}
	
	getNameByListUser (users: any): Observable<any> {
		const href = environment.host_auth + 'get-name-by-list-user';
		return this.http.post<any> (href, {users : users});
	}
	
	getUserInfo (id: number): Observable<any> {
		const url  = environment.host_auth + 'get-info-user';
		let params = new HttpParams ();
		params     = params.append ('id', id.toString ());
		return this.http.get<any> (url, {params : params});
	}
	
	getEmailRecovery (email: string): Observable<any> {
		const url = environment.host_auth + 'auth/recovery-password';
		return this.http.post<any> (url, {email : email});
	}
	
	recoveryPassword (new_password: string, token: string): Observable<any> {
		const url = environment.host_auth + 'auth/change-password';
		return this.http.post<any> (url, {password : new_password, token : token});
	}
	
	verifyToken (token: string): Observable<any> {
		const url = environment.host_auth + 'auth/verify-token-recovery';
		return this.http.post<any> (url, {token : token});
	}
	
	updateImage (id: number, file_name: string): Observable<any> {
		const url = environment.host_auth + 'update-image';
		return this.http.post<any> (url, {id : id, file_name : file_name});
	}
	
}
