import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from "@angular/common/http";
import {Observable} from "rxjs/Observable";
import {environment} from "../../../../../environments/environment";

@Injectable({
    providedIn: 'root'
})
export class DocumentService {

    constructor(private http: HttpClient) {
    }

    getListDocCategoryName(country: any = null, parent_cate: number = null): Observable<any> {
        const url  = environment.host_agent + 'get-list-doc-category-name';
        let params = new HttpParams();
        if (country != null) {
            params = params.append('country', country.toString());
        }
        if (parent_cate != null) {
            params = params.append('parent_cate', parent_cate.toString());
        }

        return this.http.get<any>(url, {params: params});
    }

    getListDocument(country: any, cooperation: any, group: any): Observable<any> {
        const url  = environment.host_agent + 'get-list-document';
        let params = new HttpParams();
        if (country) {
            params = params.append('country', country.toString());
        }
        if (cooperation) {
            params = params.append('cooperation', cooperation.toString());
        }
        if (group) {
            params = params.append('group', group.toString());
        }

        return this.http.get<any>(url, {params: params});
    }

    getDocumentById(id: number): Observable<any> {
        const url  = environment.host_agent + 'get-document-by-id';
        let params = new HttpParams();
        params     = params.append('id', id.toString());
        return this.http.get<any>(url, {params: params});
    }
}
