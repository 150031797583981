import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { BsDatepickerModule } from 'ngx-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgxPaginationModule } from 'ngx-pagination';

import { PhoneMaskDirective } from '../directives/phone-mask.directive';
import { NumberDirective } from "../directives/number.directive";
import { UpperCaseDirective } from '../directives/upper-case.directive';
import { TooltipDirective } from '../directives/tooltip.directive';
import { ConvertSerialPipe } from '../pipes/convertSerial.pipe';
import { ConvertProfilePipe } from '../pipes/convert-profile.pipe';

import { ConfirmDialogComponent } from '../components/confirm-dialog/confirm-dialog.component';
import { StatusSelectComponent } from '../components/status-select/status-select.component';
import { ProjectSelectComponent } from '../components/project-select/project-select.component';
import { ProductSelectComponent } from '../components/product-select/product-select.component';
import { GroupSelectComponent } from '../components/group-select/group-select.component';
import { BankSelectComponent } from '../components/bank-select/bank-select.component';
import { BankBranchSelectComponent } from '../components/bank-branch-select/bank-branch-select.component';
import { PerPageSelectComponent } from '../components/per-page-select/per-page-select.component';
import { PopupNotificationComponent } from '../components/popup-notification/popup-notification.component';
import { PopupUploadFileComponent } from '../components/popup-upload-file/popup-upload-file.component';
import { FileUploadModule } from 'ng2-file-upload';
import { PopupPageComponent } from '../components/popup-page/popup-page.component';
import { CitySelectComponent } from '../components/city-select/city-select.component';
import { DistrictSelectComponent } from '../components/district-select/district-select.component';
import { CardNameSelectComponent } from '../components/card-name-select/card-name-select.component';
import { SerialSelectComponent } from '../components/serial-select/serial-select.component';
import { PopupUploadFileAWSComponent } from '../components/popup-upload-file-aws/popup-upload-file-aws.component';
import {TranslateModule} from '@ngx-translate/core';
import {DocCategorySelectComponent} from "../components/doc-category-select/doc-category-select.component";
import {NationSelectComponent} from "../components/nation-select/nation-select.component";
import { StaffSelectComponent } from "../components/staff-select/staff-select.component";
import { GroupUserSelectComponent } from "../components/group-user-select/group-user-select.component";
import { UserSelectComponent } from "../components/user-select/user-select.component";

@NgModule ({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        BsDatepickerModule.forRoot(),
        NgSelectModule,
        NgbModule.forRoot(),
        FileUploadModule,
        NgxPaginationModule,
        TranslateModule
    ],
	declarations    : [
		PhoneMaskDirective,
		NumberDirective,
		UpperCaseDirective,
		TooltipDirective,
		ConvertSerialPipe,
		ConvertProfilePipe,

		ConfirmDialogComponent,
		StatusSelectComponent,
		ProjectSelectComponent,
		ProductSelectComponent,
		GroupSelectComponent,
		BankSelectComponent,
		BankBranchSelectComponent,
		PerPageSelectComponent,
		GroupSelectComponent,
		PopupNotificationComponent,
		PopupUploadFileComponent,
		PopupPageComponent,
		CitySelectComponent,
		DistrictSelectComponent,
		CardNameSelectComponent,
		SerialSelectComponent,
		PopupUploadFileAWSComponent,
    DocCategorySelectComponent,
    NationSelectComponent,
    StaffSelectComponent,
		GroupUserSelectComponent,
		UserSelectComponent
	],
	entryComponents : [
		ConfirmDialogComponent,
		StatusSelectComponent,
		ProjectSelectComponent,
		ProductSelectComponent,
		GroupSelectComponent,
		BankSelectComponent,
		BankBranchSelectComponent,
		PerPageSelectComponent,
		GroupSelectComponent,
		PopupNotificationComponent,
		PopupUploadFileComponent,
		PopupPageComponent,
		CitySelectComponent,
		DistrictSelectComponent,
		CardNameSelectComponent,
		SerialSelectComponent,
		PopupUploadFileAWSComponent,
    DocCategorySelectComponent,
    NationSelectComponent,
    StaffSelectComponent,
		GroupUserSelectComponent,
		UserSelectComponent
	],
	exports         : [
		PhoneMaskDirective,
		NumberDirective,
		UpperCaseDirective,
		TooltipDirective,
		ConvertSerialPipe,
		ConvertProfilePipe,

		ConfirmDialogComponent,
		StatusSelectComponent,
		ProjectSelectComponent,
		ProductSelectComponent,
		GroupSelectComponent,
		BankSelectComponent,
		BankBranchSelectComponent,
		PerPageSelectComponent,
		PopupNotificationComponent,
		PopupUploadFileComponent,
		PopupPageComponent,
		CitySelectComponent,
		DistrictSelectComponent,
		CardNameSelectComponent,
		SerialSelectComponent,
		PopupUploadFileAWSComponent,
		NumberDirective,
    DocCategorySelectComponent,
    NationSelectComponent,
    StaffSelectComponent,
		GroupUserSelectComponent,
		UserSelectComponent
	],
})
export class SharedModule {
}
