import { Injectable } from '@angular/core';
import { Observable } from "rxjs";
import { HttpClient, HttpParams } from "@angular/common/http";
import { environment } from "../../../../../environments/environment";

@Injectable ({
	providedIn : 'root'
})
export class ProductService {
	
	constructor (private http: HttpClient) {
	}
	
	getProductNameByAppId (app_id: string): Observable<any> {
		const url  = environment.host_crm + 'get-product-name-by-app-id';
		let params = new HttpParams ();
		params     = params.append ('app_id', app_id);
		return this.http.get<any> (url, {params : params});
	}
	
	getNameProductByAppId (appId: string): Observable<any> {
		const url = environment.host_crm + 'get-name-product-by-app-id';
		let params = new HttpParams ();
		if (appId) {
			params = params.append ('app_id', appId);
		}
		return this.http.get<any> (url, {params : params});
	}
	
	getProductNameByAppIdAndAgentId (app_id: string, agent_id: string) {
		const url  = environment.host_agent + 'get-list-product-by-agent-id-and-app-id';
		let params = new HttpParams ();
		params     = params.append ('app_id', app_id);
		params     = params.append ('agent_id', agent_id);
		return this.http.get<any> (url, {params : params});
	}
}
