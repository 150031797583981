import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { QUANTITYSHOWLIST } from "../../models/base.model";

@Component ({
	selector    : 'app-per-page-select',
	templateUrl : './per-page-select.component.html',
	styleUrls   : ['./per-page-select.component.css']
})
export class PerPageSelectComponent implements OnInit {
	
	@Input ()
	quantityShowList: any = QUANTITYSHOWLIST;
	
	@Input ()
	perPageValue: number = 10;
	
	@Output ()
	perPageChange = new EventEmitter<any> ();
	
	@Input ()
	get perPage () {
		return this.perPageValue;
	}
	
	set perPage (val) {
		this.perPageValue = val;
		this.perPageChange.emit (this.perPageValue);
	}
	
	@Output ()
	loadChange = new EventEmitter<any> ();
	
	constructor () {
	}
	
	ngOnInit () {
	}
	
	loadData () {
		this.loadChange.emit ();
	}
	
}
