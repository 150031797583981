import { Injectable } from '@angular/core';
import { Observable } from "rxjs";
import { environment } from "../../../../../environments/environment";
import { HttpClient, HttpParams } from "@angular/common/http";

@Injectable ({
    providedIn : 'root'
})
export class CityService {

    constructor (private http: HttpClient) {
    }

    getListCountry(): Observable<any> {
        const url  = environment.host_crm + 'get-list-country';
        return this.http.get<any> (url);
    }
}
