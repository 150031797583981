import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { FileUploader } from "ng2-file-upload";
import { ToasterService } from "angular2-toaster";
import { appToaster } from "../../../configs/app-toaster.config";

import { AuthenticationService } from "../../../core/authentication/authentication.service";

@Component ({
	selector    : 'app-popup-upload-file',
	templateUrl : './popup-upload-file.component.html',
	styleUrls   : ['./popup-upload-file.component.css']
})
export class PopupUploadFileComponent implements OnInit {
	uploader: FileUploader;
	
	@Input () title: string;
	@Input () url: string;
	@Input () queueLimit: number = 1;
	@Input () allowedFileType: any;
	@Input () parameter: any;
	@Input () allowedMimeType: any;
	
	public hasBaseDropZoneOver: boolean = false;
	
	fileName: string = '';
	
	constructor (public activeModal: NgbActiveModal,
	             private toasterService: ToasterService,
	             public authenticationService: AuthenticationService) {
		
	}
	
	ngOnInit () {
		this.setOptions ();
		this.uploader.onBeforeUploadItem = (item: any) => {
			item.withCredentials = false;
		};
		
		this.uploader.onCompleteItem = (item: any, response: any, status: any, headers: any) => {
			response = JSON.parse (response);
			if (response.status == 'success') {
				this.fileName = response.data.fileName;
				this.toasterService.pop ('success', appToaster.successHead, response.message);
				this.activeModal.close (this.fileName);
			} else {
				this.toasterService.pop ('error', appToaster.errorHead, response.message);
			}
		}
	}
	
	close (): void {
		this.activeModal.close (this.fileName);
	}
	
	public fileOverBase (e: any): void {
		this.hasBaseDropZoneOver = e;
	}
	
	setOptions () {
		this.uploader = new FileUploader ({queueLimit : this.queueLimit});
		let options   = {
			url                 : this.url,
			allowedFileType     : this.allowedFileType,
			allowedMimeType     : this.allowedMimeType,
			headers             : [
				{name : 'token', value : this.authenticationService.getToken ()}
			],
			additionalParameter : this.parameter
		};
		
		this.uploader.setOptions (options);
	}
	
}
