import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'convertProfile',
})
export class ConvertProfilePipe implements PipeTransform {
  transform(value: string,isManage = false): any {
    if(!isManage){
      const strArray = value.split('');
      let data = strArray
        .map((item, index) => (index >= 3 && index < strArray.length - 2 ? '*' : item))
        .toString();
      return data.replace(/[ !@#$%^&()_+\=\[\]{};':"\\|,.<>\/?]/g, '');
    }
    return value;
  }
}
