import {Component, Inject, LOCALE_ID, OnInit} from '@angular/core';
import { appSettings } from '../../../configs/app-settings.config';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToasterService } from 'angular2-toaster';
import { appToaster } from '../../../configs/app-toaster.config';

import { AgentService } from '../../../core/http/agent/agent/agent.service';
import { AuthenticationService } from '../../../core/authentication/authentication.service';
import { LogValidationErrorsService } from '../../../core/services/log-validation-errors.service';
import { Router } from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {LANGUAGE_EN, LANGUAGE_VN, LOCALE_VN} from '../../../shared/models/auth/login.model';

@Component ({
	selector    : 'app-create-agent-code',
	templateUrl : './create-agent-code.component.html',
	styleUrls   : ['./create-agent-code.component.css'],
})
export class CreateAgentCodeComponent implements OnInit {
	formErrors = {
		agent_code : '',
	};

	validationMessages = {
		agent_code : {
			required  : 'Bạn chưa điền mã đối tác.',
			pattern   : 'Mã đối tác không được chứa ký tự đặc biệt.',
			minlength : 'Mã đối tác phải từ 5 ký tự.',
			maxlength : 'Mã đối tác tối đa 15 ký tự',
		},
	};

	appSettings: any;
	loadingRandom: boolean = false;
	loadingSave: boolean   = false;
	agentId: any;

  readonly LANGUAGE_VN = LANGUAGE_VN;
  readonly LANGUAGE_EN = LANGUAGE_EN;
  language: any;

	createAgentCodeFrom: FormGroup;

	constructor (
		private fb: FormBuilder,
		private router: Router,
		private agentService: AgentService,
		private authenticationService: AuthenticationService,
		private toasterService: ToasterService,
		private logValidationErrorsService: LogValidationErrorsService,
    private translate: TranslateService,
    @Inject(LOCALE_ID) protected localeId: string,
	) {
    if (!this.authenticationService.getData("language")) {
      if (localeId == LOCALE_VN) {
        this.translate.use (LANGUAGE_VN);
        this.authenticationService.setData("language", LANGUAGE_VN)
      } else {
        this.translate.use (LANGUAGE_EN);
        this.authenticationService.setData("language", LANGUAGE_EN)
      }
    } else {
      this.translate.use(this.authenticationService.getData('language'))
    }
    this.language = this.authenticationService.getData("language")
	}

	ngOnInit () {
		this.appSettings = appSettings;
		this.agentId     = this.authenticationService.getAgentId ();
		this.createCreateAgentCodeFrom ();
		this.authenticationService.preventBackButton ();
	}

	createCreateAgentCodeFrom () {
		this.createAgentCodeFrom = this.fb.group ({
			agent_code : [null, [Validators.pattern(/^[a-zA-Z0-9]*$/), Validators.maxLength (15), Validators.minLength (5), Validators.required]],
		});
		this.createAgentCodeFrom.valueChanges.subscribe ((data) => {
			this.logValidationErrorsService.logValidationErrors (
				this.createAgentCodeFrom,
				this.formErrors,
				this.validationMessages
			);
		});
	}

	random () {
		this.loadingRandom = true;
		this.agentService.getRandomAgentCode ().subscribe (
			(res) => {
				this.loadingRandom = false;
				if (res.status === 'success') {
					this.createAgentCodeFrom.get ('agent_code').setValue (res.data.agentCode);
				} else {
					this.toasterService.pop ('error', appToaster.errorHead, res.message);
				}
			},
			(error) => {
				this.loadingRandom = false;
			}
		);
	}

	onSubmit () {
		if (this.createAgentCodeFrom.invalid) {
			return this.logValidationErrorsService.logValidationErrorsNotToucned (
				this.createAgentCodeFrom,
				this.formErrors,
				this.validationMessages
			);
		}
		if (!this.agentId) {
			this.router.navigate (['auth/login']);
			return this.toasterService.pop ('error', appToaster.errorHead, 'tài khoản chưa đăng nhập');
		}
		this.loadingSave     = true;
		let agentCode        = this.createAgentCodeFrom.get ('agent_code').value;
		let agent            = this.authenticationService.getAgentCooperation();
		let listCooperations = [];
		Object.keys(agent).forEach(key => {
			listCooperations.push(agent[key].cooperation.name);
		})
		
		this.agentService.editAgentCode (this.agentId, agentCode, listCooperations).subscribe (
			(res) => {
				this.loadingSave = false;
				if (res.status === 'success') {
					this.authenticationService.setAgentCode (agentCode).subscribe ((res) => {
						this.router.navigate (['auth/change-password']);
					});
					this.toasterService.pop ('success', appToaster.successHead, res.message);
				} else {
					this.toasterService.pop ('error', appToaster.errorHead, res.message);
				}
			},
			(error) => {
				this.loadingSave = false;
			}
		);
	}

  changeLanguage(language) {
    this.translate.use (language);
    this.authenticationService.setData("language", language);
    window.location.reload()
  }
}
