import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { ToasterService } from "angular2-toaster";
import { LogValidationErrorsService } from "../../../../core/services/log-validation-errors.service";
import { appToaster } from "../../../../configs/app-toaster.config";

import { UserAgentService } from "../../../../core/http/agent/user-agent/user-agent.service";

import { PASSWOR_REGEX } from "../../../../shared/models/base.model";

@Component ({
	selector    : 'app-edit-password',
	templateUrl : './edit-password.component.html',
	styleUrls   : ['./edit-password.component.css']
})
export class EditPasswordComponent implements OnInit {

	formErrors = {
		'password'        : '',
		'confirmPassword' : ''
	};

	validationMessages = {
		'password'        : {
			'required'  : 'Password  is required.',
			'minlength' : 'Password at less than 4 characters.',
			'maxlength' : 'Password  must be less than 20 characters.',
			'pattern'   : 'Password validators format. (password không có dấu cách và không có dấu)',
		},
		'confirmPassword' : {
			'required'  : 'ConfirmPassword  is required.',
			'minlength' : 'ConfirmPassword at least 4 characters.',
			'maxlength' : 'ConfirmPassword  must be less than 20 characters.',
			'pattern'   : 'ConfirmPassword validators format. (password không có dấu cách và không có dấu)',
		}
	};

	@Input () modalHeader: string;
	@Input () id: number;

	loadingSave: boolean = false;

	passwordForm: FormGroup;

	constructor (private fb: FormBuilder,
	             public activeModal: NgbActiveModal,
	             private toasterService: ToasterService,
	             private userAgentService: UserAgentService,
	             private logValidationErrorsService: LogValidationErrorsService) {
	}

	ngOnInit () {
		this.createPasswordForm ();
	}

	createPasswordForm () {
		this.passwordForm = this.fb.group ({
			'password'        : [
				'',
				[
					Validators.required,
					Validators.minLength (6),
					Validators.maxLength (20),
					Validators.pattern (PASSWOR_REGEX)
				]
			],
			'confirmPassword' : [
				'',
				[
					Validators.required,
					Validators.minLength (6),
					Validators.maxLength (20),
					Validators.pattern (PASSWOR_REGEX)
				]
			],
		});

		this.passwordForm.valueChanges.subscribe ((data) => {
			this.logValidationErrorsService.logValidationErrors (this.passwordForm, this.formErrors, this.validationMessages);
		});
	}

	updatePassword () {
		if (!this.passwordForm.valid) {
			return this.logValidationErrorsService.logValidationErrors (this.passwordForm, this.formErrors, this.validationMessages);
		}
		if (!this.id) {
			return this.toasterService.pop ('error', appToaster.errorHead, "lỗi tham số");
		}
		if (this.passwordForm.get ('password').value != this.passwordForm.get ('confirmPassword').value) {
			return this.toasterService.pop ('error', appToaster.errorHead, "Nhập lại mật khẩu không đúng");
		}
		let password     = this.passwordForm.get ('password').value;
		this.loadingSave = true;

		this.userAgentService.updatePasswordUser (this.id, password)
			.subscribe ((res) => {
				this.loadingSave = false;
				if (res.status === 'success') {
					this.toasterService.pop ('success', appToaster.successHead, res.message);
					this.activeModal.close (true);
				} else {
					return this.toasterService.pop ('error', appToaster.errorHead, res.message);
				}
			}, (error) => {
				this.loadingSave = false;
			});
	}

	close (): void {
		this.activeModal.close (false);
	}

}
