import { Injectable } from '@angular/core';
import { Observable } from "rxjs";
import { environment } from "../../../../../environments/environment";
import { HttpClient, HttpParams } from "@angular/common/http";
import { AgentChildSearchModel } from "../../../../shared/models/agent/agent-child-search.model";
import { AgentModel } from "../../../../shared/models/agent/agent.model";

@Injectable ({
	providedIn : 'root'
})
export class AgentService {

	constructor (private http: HttpClient) {
	}

	getInfoAgent (id: number): Observable<any> {
		const url  = environment.host_agent + 'get-info-agent';
		let params = new HttpParams ();
		params     = params.append ('agent_id', id.toString ());

		return this.http.get<any> (url, {params : params});
	}

	getAllGroup (): Observable<any> {
		const url = environment.host_agent + 'get-all-group';

		return this.http.get<any> (url);
	}

	editAgent (agent: AgentModel): Observable<any> {
		const url = environment.host_agent + 'edit-agent';

		return this.http.post (url, agent);
	}

	editAgentCode (id: any, agent_code: any, list_cooperation: any): Observable<any> {
		const url = environment.host_agent + 'edit-agent-code';

		return this.http.post (url, {id: id , agent_code: agent_code, list_cooperation: list_cooperation});
	}

	getRandomAgentCode (): Observable<any> {
		const url = environment.host_agent + 'get-random-agent-code';
		return this.http.get<any> (url);
	}

	getListAgentChild (agentChildSearchModel: AgentChildSearchModel): Observable<any> {
		const url  = environment.host_agent + 'get-list-agent-child';
		let params = new HttpParams ();
		params     = params.append ('page', agentChildSearchModel.page.toString ());
		params     = params.append ('limit', agentChildSearchModel.limit.toString ());
		params     = params.append ('agent_id', agentChildSearchModel.agent_id.toString ());

		if (agentChildSearchModel.agent_code != null) {
			params = params.append ('agent_code', agentChildSearchModel.agent_code);
		}
		if (agentChildSearchModel.email != null) {
			params = params.append ('email', agentChildSearchModel.email);
		}
		if (agentChildSearchModel.phone != null) {
			params = params.append ('phone', agentChildSearchModel.phone);
		}
		if (agentChildSearchModel.status != null) {
			params = params.append ('status', agentChildSearchModel.status.toString ());
		}

		return this.http.get<any> (url, {params : params});
	}

  getListStaff(agentModel: AgentModel): Observable<any> {
    const url  = environment.host_agent + 'get-list-staff-id-by-agent-id';
    let params = new HttpParams ();
    if(agentModel.parent_id) {
      params     = params.append ('parent_id', agentModel.parent_id.toString());
    }
    if(agentModel.email != null) {
      params     = params.append ('email', agentModel.email.toString());
    }
    if(agentModel.name != null) {
      params     = params.append ('name', agentModel.name.toString());
    }
    if(agentModel.status != null) {
      params     = params.append ('status', agentModel.status.toString());
    }

    return this.http.get<any> (url, {params : params});
  }

  editStaff(agent: AgentModel): Observable<any> {
	  const url = environment.host_agent + 'edit-staff';
    return this.http.post<any> (url, agent);
  }
}
