import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { NgModule } from '@angular/core';

import { AppComponent } from './app.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';

import { AppRoutingModule } from './app-routing.module';
import { CoreModule } from './core/core.module';
import { ThemeModule } from './core/theme.module';
import { SharedModule } from "./shared/module/shared.module";
import { TemplateCoreModule } from './core/template-core.module';
import { ToasterModule } from 'angular2-toaster';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { BootstrapModalModule } from "ng2-bootstrap-modal";
import { NgxPaginationModule } from 'ngx-pagination';
import { ClientAuthGuard } from "./core/guards/client-auth.guard";
import { NgSelectModule } from '@ng-select/ng-select';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { SocialLoginModule, AuthServiceConfig, GoogleLoginProvider } from "angular-6-social-login";
import {AuthModule} from './modules/auth/auth.module';
import { LanguageInterceptor } from './core/interceptors/language.interceptor';
import { HttpClientModule, HTTP_INTERCEPTORS} from '@angular/common/http';

export function getAuthServiceConfigs () {
	let config = new AuthServiceConfig (
		[
			{
				id       : GoogleLoginProvider.PROVIDER_ID,
				provider : new GoogleLoginProvider (environment.google_client_id)
			}
		]
	);
	return config;
}

@NgModule ({
	declarations : [
		AppComponent,
	],
	imports      : [
		BrowserModule,
		BrowserAnimationsModule,
		ServiceWorkerModule.register ('/ngsw-worker.js', {enabled : environment.production}),
		AppRoutingModule,
		CoreModule,
		ThemeModule.forRoot (),
		SharedModule,
		TemplateCoreModule.forRoot (),
		ToasterModule.forRoot (),
		NgbModule.forRoot (),
		BootstrapModalModule,
		NgxPaginationModule,
		NgSelectModule,
		BsDatepickerModule.forRoot (),
		SocialLoginModule,
    AuthModule
	],
	providers    : [
		ClientAuthGuard,
		{
			provide: AuthServiceConfig,
			useFactory: getAuthServiceConfigs
		},
		{
			provide: HTTP_INTERCEPTORS,
			useClass: LanguageInterceptor,
			multi: true,
		},
	],

	bootstrap    : [AppComponent]
})
export class AppModule {
}
