import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/map';
import { tap } from 'rxjs/operators';
import { of as observableOf } from 'rxjs';
import { ToasterService } from 'angular2-toaster';

import { Login } from '../../shared/models/auth/login.model';
import { environment } from '../../../environments/environment';
import { AFFI_STAFF, AFFILIATE, AGENT_ADMIN, AGENT_AFFI_STAFF, MANAGE } from '../../shared/models/global/role.model';
import { appToaster } from '../../configs/app-toaster.config';
import { LocationStrategy } from '@angular/common';
import {DEFAULT_COUNTRY, DEFAULT_LANGUAGE} from '../../shared/models/base.model';

const credentialsKey       = 'currentUser';
const accessTokenKey       = 'accessToken';
const agentKey             = 'agent';
const language             = 'language';
const country              = 'country';
const listViewKey          = 'listView';
const viewnKey             = 'view';
const listCountryKey       = 'listCountryKey';
const listRouterViewKey    = 'listRouterView';
const listRouterAndViewKey = 'listRouterAndView';
const popupKey             = 'popup';

@Injectable()
export class AuthenticationService {
  constructor(
    private router: Router,
    private toasterService: ToasterService,
    private http: HttpClient,
    private locationStrategy: LocationStrategy
  ) {}

  login(loginData: Login): Observable<any> {
    const href = environment.host_auth + 'auth/login-agent';
    return this.http.post<any>(href, loginData).pipe(
      tap((item) => {
        if (item.status === 'success') {
          this.dataLogin(item.data);
        }
        return item;
      })
    );
  }

  loginWithGoogle(token: string): Observable<any> {
    const href = environment.host_auth + 'auth/login-agent-with-google';
    return this.http
      .post<any>(href, { token: token })
      .pipe(
        tap((item) => {
          if (item.status === 'success') {
            this.dataLogin(item.data);
          }
          return item;
        })
      );
  }

  dataLogin(data) {
    // saved User
    this.setData(credentialsKey, JSON.stringify(data.users));
    // saved listRouterView
    this.setData(listRouterViewKey, JSON.stringify(data.listRouterView));
    // saved Token
    this.setData(accessTokenKey, data.access_token);
	// saved list Language
    this.setData(listCountryKey,JSON.stringify( data.countryView));
    // saved Token
    this.setData(agentKey, JSON.stringify(data.agent));
    //saved language
    this.setData (language, DEFAULT_LANGUAGE);
    //saved Country
	  let countryId = null;
	  Object.keys (data.countryView).forEach ((key) => {
		  countryId = key;
	  });
	  this.setData (country, countryId);
    // saved listView
    let listView = {};
    let view = 0;
    let returnUrl = '';
    let listRouterAndView = {};
    Object.keys(data.listRouterView).forEach((key) => {
      listView[key] = data.listRouterView[key]['name'];
      if (view == 0) {
        view = parseInt(key);
        Object.keys(data.listRouterView[key]['router']).forEach((keyRouter) => {
          if (
            returnUrl == '' ||
            keyRouter == 'agent/dashbroad-affi' ||
            keyRouter == 'agent/dashbroad-hold-card' ||
            keyRouter == 'agent/dashbroad-deposit'
          ) {
            returnUrl = '/' + keyRouter;
          }
        });
      }
      Object.keys(data.listRouterView[key]['router']).forEach((keyRouter) => {
        listRouterAndView[keyRouter] = key;
      });
    });
    this.setData(listViewKey, JSON.stringify(listView));
    // listRouterAndViewKey
    this.setData(listRouterAndViewKey, JSON.stringify(listRouterAndView));
    // saved viewnKey
    this.setData(viewnKey, view.toString());

    if (!data.agent['agent_code']) {
      return this.router.navigate(['auth/create-agent-code']);
    }

    if (data.users['login_time'] === 0) {
      return this.router.navigate(['auth/change-password']);
    }
    // router navigate
    setTimeout(() => {
      if (returnUrl != '') {
        this.router.navigate([returnUrl]);
      } else {
        this.toasterService.pop('success', appToaster.successHead, 'Permission denied');
      }
    }, 0);
  }

  setAgentCode(agentCode): Observable<boolean> {
    let agent = this.getData(agentKey);
    agent = JSON.parse(agent);
    agent['agent_code'] = agentCode;
    this.setData(agentKey, JSON.stringify(agent));
    return observableOf(true);
  }

  navigateDashbroad() {
    let returnUrl = '';
    let view = this.getData(viewnKey);
    let listRouterAndView = this.getData(listRouterAndViewKey);
    listRouterAndView = JSON.parse(listRouterAndView);
    Object.keys(listRouterAndView).forEach((key) => {
      Object.keys(listRouterAndView).forEach((keyRouter) => {
        if (
          returnUrl == '' ||
          keyRouter == 'agent/dashbroad-affi' ||
          keyRouter == 'agent/list-order-hold-card' ||
          keyRouter == 'agent/list-order-deposit'
        ) {
          returnUrl = '/' + keyRouter;
        }
      });
    });
    // router navigate
    setTimeout(() => {
      if (returnUrl != '') {
        this.router.navigate([returnUrl]);
      } else {
        this.toasterService.pop('success', appToaster.successHead, 'Permission denied');
      }
    }, 0);
  }

  logout(): Observable<boolean> {
    let dataPopup = this.getData(popupKey);
    this.removeAll();
    if (dataPopup != null) {
      this.setData(popupKey, dataPopup);
    }
    return observableOf(true);
  }
  
  getUserInfo(): Observable<any> {
    const savedCredentials = this.getUser();
    return observableOf(savedCredentials);
  }

  getAgentInfo(): Observable<any> {
    const savedAgent = this.getAgent();
    return observableOf(savedAgent);
  }

  getListViewInfo(): Observable<any> {
    const savedListView = this.getListView();
    return observableOf(savedListView);
  }

  getRouterViewInfo(): Observable<any> {
    const saveRouterView = this.getRouterView();
    return observableOf(saveRouterView);
  }

  getRouterAndViewInfo(): Observable<any> {
    const saveRouterAndView = this.getRouterAndView();
    return observableOf(saveRouterAndView);
  }

  getViewInfo(): Observable<any> {
    const savedView = this.getView();
    return observableOf(savedView);
  }

  setViewInfo(view): Observable<any> {
    this.setData(viewnKey, view.toString());
    return observableOf();
  }

  getUserRole(): Observable<any> {
    const savedCredentials = this.getUser();
    return observableOf(savedCredentials['role_ids']);
  }

  getCountryInfo (): Observable<any> {
    const savedView = this.getCountry();
    return observableOf (savedView);
  }

  getCountry () {
    let view = this.getData (country);
    return JSON.parse (view);
  }

  isLogin() {
    if (this.getData(credentialsKey)) {
      return true;
    }
    return false;
  }

  getToken() {
    return this.getData(accessTokenKey);
  }

  getUserType() {
    const savedCredentials = this.getUser();
    if (this.isLogin()) {
      // return savedCredentials['role'];
      return 'client';
    } else {
      return false;
    }
  }

  //User
  setUser(data) {
    this.setData(credentialsKey, JSON.stringify(data));
  }

  getUser() {
    const savedCredentials = this.getData(credentialsKey);
    return JSON.parse(savedCredentials);
  }

  getUserId() {
    const savedCredentials = this.getData(credentialsKey);
    let user = JSON.parse(savedCredentials);
    return user['id'];
  }

  //Agent
  getAgent() {
    const savedAgent = this.getData(agentKey);
    return JSON.parse(savedAgent);
  }

  getAgentId() {
    const savedAgent = this.getData(agentKey);
    let agent = JSON.parse(savedAgent);
    return agent['id'];
  }

  getAgentCode() {
    const savedAgent = this.getData(agentKey);
    let agent = JSON.parse(savedAgent);
    return agent['agent_code'];
  }

  getAgentCooperation() {
    const savedAgent = this.getData(agentKey);
    let agent = JSON.parse(savedAgent);
    return agent['cooperation'];
  }

  getRole() {
    const savedCredentials = this.getData(credentialsKey);
    let user = JSON.parse(savedCredentials);
    return user['role_ids'];
  }

  getRoleName() {
    const savedCredentials = this.getData(credentialsKey);
    let user = JSON.parse(savedCredentials);
    return user['role_name'];
  }

  isAgentAdmin() {
    let listRole = this.getRoleName();
    if (listRole[AGENT_ADMIN]) {
      return true;
    }
    return false;
  }

  isManage() {
    let listRole = this.getRoleName();
    if (listRole[MANAGE]) {
      return true;
    }
    return false;
  }

  isAffiliate() {
    let listRole = this.getRoleName();
    if (listRole[AFFILIATE]) {
      return true;
    }
    return false;
  }
  
  isAffiStaff() {
    let listRole = this.getRoleName();
    if (listRole[AFFI_STAFF]) {
      return true;
    }
    return false;
  }
  
  isAgentAffiStaff() {
    let listRole = this.getRoleName();
    if (listRole[AGENT_AFFI_STAFF]) {
      return true;
    }
    return false;
  }

  getListView() {
    let listView = this.getData(listViewKey);
    return JSON.parse(listView);
  }

  getRouterView() {
    let listRouterView = this.getData(listRouterViewKey);
    return JSON.parse(listRouterView);
  }

  getRouterAndView() {
    let listRouterAndView = this.getData(listRouterAndViewKey);
    return JSON.parse(listRouterAndView);
  }

  getView() {
    let view = this.getData(viewnKey);
    return JSON.parse(view);
  }

  removeAll(): void {
    sessionStorage.clear();
    localStorage.clear();
  }

  setData(key, data) {
    sessionStorage.setItem(key, data);
    localStorage.setItem(key, data);
  }

  getData(key) {
    return sessionStorage.getItem(key) || localStorage.getItem(key);
  }

  removeData(key) {
    sessionStorage.removeItem(key);
    localStorage.removeItem(key);
  }

  loginAdmin(agent_id: string, user_id: string): Observable<any> {
    const href = environment.host_auth + 'auth/login-admin';
    return this.http
      .post<any>(href, { agent_id: agent_id, user_id: user_id })
      .pipe(
        tap((item) => {
          if (item.status === 'success') {
            // saved User
            this.setData(credentialsKey, JSON.stringify(item.data.users));
            // saved listRouterView
            this.setData(listRouterViewKey, JSON.stringify(item.data.listRouterView));
            // saved Token
            this.setData(accessTokenKey, item.data.access_token);
            // saved Token
            this.setData(agentKey, JSON.stringify(item.data.agent));
            // saved listView
            let listView = {};
            let view = 0;
            let listRouterAndView = {};
            Object.keys(item.data.listRouterView).forEach((key) => {
              listView[key] = item.data.listRouterView[key]['name'];
              Object.keys(item.data.listRouterView[key]['router']).forEach((keyRouter) => {
                listRouterAndView[keyRouter] = key;
              });
            });
            this.setData(listViewKey, JSON.stringify(listView));
            // listRouterAndViewKey
            this.setData(listRouterAndViewKey, JSON.stringify(listRouterAndView));
            // saved viewnKey
            sessionStorage.setItem(viewnKey, view.toString());
          }
          return item;
        })
      );
  }
  
  // Get Country
	getListCountryInfo (): Observable<any> {
		const savedView = this.getListCountry();
		return observableOf (savedView);
	}
	
	getListCountry () {
		let view = this.getData (listCountryKey);
		return JSON.parse (view);
	}
	
	setCountry (countryId): Observable<any> {
		this.setData (country, countryId);
		return observableOf ();
	}
	
  preventBackButton() {
    history.pushState(null, null, location.href);
    this.locationStrategy.onPopState(() => {
      history.pushState(null, null, location.href);
    });
  }
}
