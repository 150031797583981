import { Injectable } from '@angular/core';
import { FormGroup } from "@angular/forms";

@Injectable ({
	providedIn : 'root'
})
export class LogValidationErrorsService {
	
	constructor () {
	}
	
	logValidationErrors (group: FormGroup, formErrors: any, validationMessages: any): void {
		Object.keys (group.controls).forEach ((key: string) => {
			const abstractControl = group.get (key);
			if (abstractControl instanceof FormGroup) {
				this.logValidationErrors (abstractControl, formErrors, validationMessages);
			} else {
				formErrors[key] = '';
				if (abstractControl && !abstractControl.valid
					&& ( abstractControl.touched || abstractControl.dirty )) {
					const messages = validationMessages[key];
					for (const errorKey in abstractControl.errors) {
						if (errorKey) {
							formErrors[key] += messages[errorKey] + ' ';
						}
					}
				}
			}
		});
	}
	
	logValidationErrorsNotToucned (group: FormGroup, formErrors: any, validationMessages: any): void {
		Object.keys (group.controls).forEach ((key: string) => {
			const abstractControl = group.get (key);
			if (abstractControl instanceof FormGroup) {
				this.logValidationErrorsNotToucned (abstractControl, formErrors, validationMessages);
			} else {
				formErrors[key] = '';
				if (abstractControl && !abstractControl.valid) {
					const messages = validationMessages[key];
					for (const errorKey in abstractControl.errors) {
						if (errorKey) {
							formErrors[key] += messages[errorKey] + ' ';
						}
					}
				}
			}
		});
	}
	
}
