import { Component } from '@angular/core';
import { ToasterConfig } from 'angular2-toaster';

@Component ({
	selector    : 'app-root',
	templateUrl : './app.component.html',
	styleUrls   : ['./app.component.css']
})
export class AppComponent {

	public config: ToasterConfig = new ToasterConfig ({
		animation       : 'flyRight',
		positionClass   : 'toast-top-right',
		showCloseButton : true,
		limit           : 20,
		timeout         : 8000
	});

  title = 'Early start';
}
