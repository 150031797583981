import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from "@angular/common/http";
import { Observable } from "rxjs";
import { environment } from "../../../../../environments/environment";
import { UserSearchModel } from "../../../../shared/models/auth/user-search.model";
import { User } from "../../../../shared/models/auth/user.model";

@Injectable ({
	providedIn : 'root'
})
export class UserAgentService {

	constructor (private http: HttpClient) {
	}

	getListUserAgent (userSearchModel: UserSearchModel): Observable<any> {
		const url = environment.host_auth + 'get-list-agent-staff';

		let params = new HttpParams ();
		params     = params.append ('page', userSearchModel.page.toString ());
		params     = params.append ('limit', userSearchModel.limit.toString ());
		params     = params.append ('agent_id', userSearchModel.agent_id.toString ());
		
		if (userSearchModel.fullname) {
			params = params.append ('fullname', userSearchModel.fullname);
		}
		if (userSearchModel.email) {
			params = params.append ('email', userSearchModel.email);
		}
		if (userSearchModel.status != null) {
			params = params.append ('status', userSearchModel.status.toString ());
		}
		if (userSearchModel.list_role_name != null) {
			params = params.append ('list_role_name', userSearchModel.list_role_name.toString ());
		}
		return this.http.get<any> (url, {params : params});
	}

	getListRoleAgent (): Observable<any> {
		const url = environment.host_auth + 'get-list-role-agent';

		return this.http.get<any> (url);
	}

	updateStatusUser (id: number, status: number): Observable<any> {
		const url = environment.host_auth + 'update-status-user';
		return this.http.post<any> (url, {id : id, status : status});
	}

	editUserAgent (user: User): Observable<any> {
		const href = environment.host_auth + 'edit-user-agent';
		return this.http.post<any> (href, user);
	}

	editInfoUserById (user: User): Observable<any> {
		const href = environment.host_auth + 'edit-info-user-by-id';
		return this.http.post<any> (href, user);
	}

	updatePasswordUser (id: number, password: string): Observable<any> {
		const href = environment.host_auth + 'update-password-user';
		return this.http.post<any> (href, {id : id, password : password});
	}

	getAllUserAgent (agent_id: number): Observable<any> {
		const url = environment.host_auth + 'get-list-user-agent';

		let params = new HttpParams ();
		params     = params.append ('agent_id', agent_id.toString ());

		return this.http.get<any> (url, {params : params});
	}
	
	getListHistoryActivity (id: number, page: number, limit: number): Observable<any> {

		const url = environment.host_auth + 'get-list-history-activity';

		let params = new HttpParams ();
		params     = params.append ('id', id.toString ());
		params     = params.append ('page', page.toString ());
		params     = params.append ('limit', limit.toString ());

		return this.http.get<any> (url, {params : params});
	}

	checkUserAgentStaffAffi(user_id): Observable<any> {
	  const url = environment.host_auth + 'check-user-staff-affi';

    let params = new HttpParams ();
    params     = params.append ('user_id', user_id.toString ());
    return this.http.get<any> (url, {params : params});
  }
}
