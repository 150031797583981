import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { NbMenuService, NbSidebarService } from '@nebular/theme';
import { ToasterService } from 'angular2-toaster';

import { AuthenticationService } from '../../authentication/authentication.service';
import { AnalyticsService } from '../../services/analytics.service';

import { appSettings } from '../../../configs/app-settings.config';
import { appToaster } from '../../../configs/app-toaster.config';
import { environment } from '../../../../environments/environment';
import { PopupPageComponent } from "../../../shared/components/popup-page/popup-page.component";
import { PageService } from "../../http/agent/page/page.service";

@Component ({
	selector    : 'app-header',
	styleUrls   : ['./header.component.scss'],
	templateUrl : './header.component.html',
})
export class HeaderComponent implements OnInit {
	
	user: any         = {};
	agent: any        = {};
	appSettings: any  = {};
	listView: any     = {};
	titleView: string = '';
	viewKey: number   = 0;
	linkImage:string;
	
	titleCountry: string = '';
	listCountryKey: any  = {};
	country: number      = null;
	
	
	@Output ()
	viewKeyChange = new EventEmitter<any> ();
	
	constructor (private sidebarService: NbSidebarService,
	             private router: Router,
	             private menuService: NbMenuService,
	             private toasterService: ToasterService,
	             private authenticationService: AuthenticationService,
	             private analyticsService: AnalyticsService,
	             private modalService: NgbModal,
	             private pageService: PageService,
	) {
	}
	
	ngOnInit () {
		this.appSettings = appSettings;
		
		// User
		this.authenticationService.getUserInfo ()
			.subscribe ((res: any) => {
				this.user = res;
				if (this.user.image) {
					this.linkImage = environment.host_auth_download + this.user.image;
				}
			});
		
		// Agent
		this.authenticationService.getAgentInfo ()
			.subscribe ((res: any) => {
				this.agent = res;
			});
		
		// viewKey
		this.authenticationService.getViewInfo ()
			.subscribe ((viewKey: any) => {
				this.viewKey = parseInt (viewKey);
				this.viewKeyChange.emit (parseInt (viewKey));
				// ListView
				this.authenticationService.getListViewInfo ()
					.subscribe ((listView: any) => {
						this.listView  = listView;
						this.titleView = this.listView[this.viewKey];
					});
			});
		
		// Country
		this.authenticationService.getCountryInfo ().subscribe ((viewKey: any) => {
			this.country = parseInt (viewKey);
			// this.viewKeyChange.emit (parseInt (viewKey));
			// ListCountry
			this.authenticationService.getListCountryInfo ().subscribe ((listCountry: any) => {
				this.listCountryKey = listCountry;
				this.titleCountry   = this.listCountryKey[this.country];
			});
		});
		
		this.menuService.onItemClick ()
			.subscribe ((event) => {
			
			});
		
	}
	
	onClickView (viewKey) {
		this.viewKey   = parseInt (viewKey);
		this.titleView = this.listView[viewKey];
		this.authenticationService.setViewInfo (viewKey);
		this.viewKeyChange.emit (parseInt (viewKey));
	}
	
	onClickCountry(country){
		this.country   = parseInt (country);
		this.titleCountry = this.listCountryKey[country];
		this.authenticationService.setCountry (country);
		this.getPopupDisplay();
	}
	
	getPopupDisplay () {
		let country_code = localStorage.getItem("country");
		this.pageService.getPopupDisplay (country_code)
			.subscribe ((res) => {
				if (res.status == 'success') {
					let listPopupDisplay = res.data;
					if (listPopupDisplay.length > 0) {
						const modalRef                              = this.modalService.open (PopupPageComponent, {size : 'lg', backdrop : 'static'});
						modalRef.componentInstance.listPopupDisplay = listPopupDisplay;
					}
				}
			});
	}
	toggleSidebar (): boolean {
		this.sidebarService.toggle (true, 'menu-sidebar');
		return false;
	}
	
	
	goToHome () {
		this.menuService.navigateHome ();
	}
	
	startSearch () {
		this.analyticsService.trackEvent ('startSearch');
	}
	
	logout () {
		this.authenticationService.logout ().subscribe ((res) => {
			let logOutURL = '/auth/login';
			this.toasterService.pop ('success', appToaster.successHead, "Đăng xuất thanh công.");
			this.router.navigate ([logOutURL], {replaceUrl : true});
		});
	}
	
	lockScreen() {
		this.authenticationService.logout ().subscribe ((res) => {
			let logOutURL = '/auth/login';
			this.toasterService.pop ('success', appToaster.successHead, "Đăng xuất thanh công.");
			this.router.navigate ([logOutURL], {replaceUrl : true});
		});
	}
}
