import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from "@angular/common/http";
import { Observable } from "rxjs";
import { environment } from 'src/environments/environment';

@Injectable ({
  providedIn : 'root'
})
export class FileService {

  constructor (private http: HttpClient) {
  }

  deleteFiles (list_files: string[]) {
    const url = environment.host_media + "delete-files";
    return this.http.post<any> (url, {'list_files' : list_files});
  }

  copyFiles (list_files: string[], folder_received_path: string) {
    const url = environment.host_media + "copy-files";
    return this.http.post<any> (url, {'list_files' : list_files, 'folder_received_path' : folder_received_path});
  }

  moveFiles (list_files: string[], folder_received_path: string) {
    const url = environment.host_media + "move-files";
    return this.http.post<any> (url, {'list_files' : list_files, 'folder_received_path' : folder_received_path});
  }

  renameFile (file_path: string, new_name: string): Observable<any> {
    const url = environment.host_media + "rename-file";
    return this.http.post<any> (url, {'file_path' : file_path, 'new_name' : new_name});
  }

  getListFiles (folder_path: string, page: number, limit: number): Observable<any> {
    const url  = environment.host_media + "get-list-files";
    let params = new HttpParams ();
    params     = params.append ('folder_path', folder_path);
    params     = params.append ('page', String (page));
    params     = params.append ('limit', String (limit));
    return this.http.get<any> (url, {params : params});
  }
}
