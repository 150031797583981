import {Component, Inject, LOCALE_ID, OnInit} from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ToasterService } from 'angular2-toaster';
import { UserService } from 'src/app/core/http/global/user/user.service';
import { LogValidationErrorsService } from 'src/app/core/services/log-validation-errors.service';
import { appToaster } from 'src/app/configs/app-toaster.config';
import { AuthenticationService } from 'src/app/core/authentication/authentication.service';
import { Router } from '@angular/router';
import { LocationStrategy } from '@angular/common';
import {LANGUAGE_EN, LANGUAGE_VN, LOCALE_VN} from '../../../shared/models/auth/login.model';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.css']
})
export class ChangePasswordComponent implements OnInit {
  resetPasswordForm: FormGroup;
  loadingSearch: boolean = false;

  readonly LANGUAGE_VN = LANGUAGE_VN;
  readonly LANGUAGE_EN = LANGUAGE_EN;
  language: any;
  
  formErrorsResetPassword = {
    password: '',
    confirmPassword: '',
  };
  validationMessagesResetPassword = {
    password: {
      required: 'Bạn chưa nhập mật khẩu.',
      minlength: 'Mật khẩu ít hơn 6 ký tự.',
      maxlength: 'Mật khẩu phải dưới 20 ký tự.',
    },
    confirmPassword: {
      required: 'Bạn chưa nhập xác nhận mật khẩu .',
      minlength: 'Xác nhận mất khẩu ít hơn 6 ký tự.',
      maxlength: 'xác nhận mật khẩu phải dưới 20 ký tự.',
    },
  };
  constructor(
    private fb: FormBuilder,
    private toasterService: ToasterService,
    private userService: UserService,
    private authenticationService: AuthenticationService,
    private logValidationErrorsService: LogValidationErrorsService,
    private router: Router,
    private locationStrategy: LocationStrategy,
    private translate: TranslateService,
    @Inject(LOCALE_ID) protected localeId: string,
  ) {
    if (!this.authenticationService.getData("language")) {
      if (localeId == LOCALE_VN) {
        this.translate.use (LANGUAGE_VN);
        this.authenticationService.setData("language", LANGUAGE_VN)
      } else {
        this.translate.use (LANGUAGE_EN);
        this.authenticationService.setData("language", LANGUAGE_EN)
      }
    } else {
      this.translate.use(this.authenticationService.getData('language'))
    }
    this.language = this.authenticationService.getData("language")
  }

  ngOnInit() {
    this.createResetPasswordForm();
    this.authenticationService.preventBackButton();
  }
  createResetPasswordForm() {
    this.resetPasswordForm = this.fb.group({
      password: ['', [Validators.required, Validators.minLength(6), Validators.maxLength(20)]],
      confirmPassword: [
        '',
        [Validators.required, Validators.minLength(6), Validators.maxLength(20)],
      ],
    });

    this.resetPasswordForm.valueChanges.subscribe((data) => {
      this.logValidationErrorsService.logValidationErrors(
        this.resetPasswordForm,
        this.formErrorsResetPassword,
        this.validationMessagesResetPassword
      );
    });
  }

  toggleType(inputName, nameIcon) {
    if (inputName.value) {
      inputName.type = inputName.type == 'password' ? 'text' : 'password';
      nameIcon['className'] =
        nameIcon['className'] === 'fa fa-eye-slash' ? 'fa fa-eye' : 'fa fa-eye-slash';
    }
  }

  onResetPassword() {
    if (!this.resetPasswordForm.valid) {
      return this.logValidationErrorsService.logValidationErrors(
        this.resetPasswordForm,
        this.formErrorsResetPassword,
        this.validationMessagesResetPassword
      );
    }
    if (
      this.resetPasswordForm.get('password').value !=
      this.resetPasswordForm.get('confirmPassword').value
    ) {
      return this.toasterService.pop('error', appToaster.errorHead, 'Nhập lại mật khẩu không đúng');
    }
    let password = this.resetPasswordForm.get('password').value;
    let id = this.authenticationService.getUserId();
    this.loadingSearch = true;

    this.userService.updatePasswordUser(id, password).subscribe(
      (res) => {
        this.loadingSearch = false;
        if (res.status === 'success') {
          this.resetPasswordForm.reset();
          this.authenticationService.navigateDashbroad();
          this.toasterService.pop('success', appToaster.successHead, res.message);
        } else {
          return this.toasterService.pop('error', appToaster.errorHead, res.message);
        }
      },
      (err) => {
        return this.toasterService.pop('error', appToaster.errorHead, err.message);
      }
    );
  }

  changeLanguage(language) {
    this.translate.use (language);
    this.authenticationService.setData("language", language);
    window.location.reload()
  }
}
