import { Directive, ElementRef, HostListener, OnInit } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive ({
	selector : '[numbersOnly]'
})
export class NumberDirective implements OnInit {
	
	constructor (
		private ref: ElementRef,
		public ngControl: NgControl) {
	}
	
	
	ngOnInit () {
		let newVal = JSON.parse (JSON.stringify (this.ngControl.control.value));
		newVal     = this.converNumber (newVal);
		
		this.ref.nativeElement.value = newVal;
		setTimeout (() => {
			this.ngControl.control.setValue (newVal);
		}, 0);
	}
	
	@HostListener ('input', ['$event'])
	onInput (event) {
		const initalValue = this.ref.nativeElement.value;
		let newVal        = this.converNumber (initalValue);
		
		this.ref.nativeElement.value = newVal;
		if (initalValue !== this.ref.nativeElement.value) {
			event.stopPropagation ();
		}
		this.ngControl.control.setValue (newVal);
	}
	
	converNumber (newVal) {
		newVal = newVal.replace (/[^0-9]*/g, '');
		return newVal;
	}
	
}
