import {Component, Input, OnInit} from '@angular/core';
import {AuthenticationService} from "../../authentication/authentication.service";
import {Router} from "@angular/router";
import {ToasterService} from "angular2-toaster";

@Component ({
	selector    : 'app-nav',
	templateUrl : './nav.component.html',
	styleUrls   : ['./nav.component.css']
})
export class NavComponent implements OnInit {

	viewKeyValue: number = 0;

	listPackageOfDocument: any = {};

	@Input ()
	get viewKey () {
		return this.viewKeyValue;
	};

	set viewKey (val) {
		if (val) {
			this.routerView   = this.routerViewFull[val]['router'];
			this.viewKeyValue = val;
		} else {
			this.routerView = {};
		}
	};

	routerViewFull: any = {};
	routerView: any     = {};

	constructor (
		private router: Router,
		private toasterService: ToasterService,
		private authenticationService: AuthenticationService,
	) {
		// RouterViewInfo
		this.authenticationService.getRouterViewInfo ()
			.subscribe ((routerView: any) => {
				this.routerViewFull = routerView
			});

		// this.userId = this.authenticationService.get;
	}

	ngOnInit () {
	}

	clickRouter (returnUrl) {
		this.router.navigateByUrl ('/', {skipLocationChange : true}).then (() =>
			this.router.navigate ([returnUrl])
		);
	}

}
