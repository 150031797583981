import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from "@angular/router";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";

import { ToasterService } from "angular2-toaster";
import { UserService } from "../../../../core/http/global/user/user.service";
import { AuthenticationService } from "../../../../core/authentication/authentication.service";

import { PopupUploadFileComponent } from "../../../../shared/components/popup-upload-file/popup-upload-file.component";
import { environment } from "../../../../../environments/environment";
import { appToaster } from "../../../../configs/app-toaster.config";

const URL = environment.host_auth + 'upload-file';

@Component ({
	selector    : 'app-profile',
	templateUrl : './profile.component.html',
	styleUrls   : ['./profile.component.css']
})
export class ProfileComponent implements OnInit {
	readonly VIEWHISTORY = 'view-history';
	
	loadingSearch: boolean = false;
	isViewHistory: boolean = false;
	
	user: any = {};
	
	linkImage: string;
	
	constructor (private userService: UserService,
	             private router: Router,
	             private modalService: NgbModal,
	             private toasterService: ToasterService,
	             private activatedRoute: ActivatedRoute,
	             private authenticationService: AuthenticationService) {
	}
	
	ngOnInit () {
		this.activatedRoute.params.subscribe ((params: Params) => {
			let type = params['type'];
			if (type == this.VIEWHISTORY) {
				this.isViewHistory = true;
			}
		});
		this.getInfoUser ();
	}
	
	getInfoUser () {
		this.loadingSearch = true;
		let id             = this.authenticationService.getUserId ();
		this.userService.getUserInfo (id)
			.subscribe ((res) => {
				if (res.status === 'success') {
					this.loadingSearch = false;
					this.user          = res.data;
					if (this.user.image) {
						this.linkImage = environment.host_auth_download + this.user.image;
					}
				} else {
					this.toasterService.pop ('error', appToaster.errorHead, res.message);
				}
			}, (err) => {
				this.toasterService.pop ('error', appToaster.errorHead, err.message);
			});
	}
	
	uploadAvatar () {
		const modalRef = this.modalService.open (PopupUploadFileComponent, {size : 'lg', backdrop : 'static'});
		
		modalRef.componentInstance.title           = 'Cập nhật ảnh đại diện';
		modalRef.componentInstance.url             = URL;
		modalRef.componentInstance.queueLimit      = 1;
		modalRef.componentInstance.allowedMimeType = ['image/png', 'image/jpg', 'image/jpeg'];
		
		modalRef.result.then ((result) => {
			if (result) {
				this.updateImage(result);
			}
		}, (reason) => {
		});
	}
	
	updateImage (image) {
		this.userService.updateImage (this.user.id, image)
			.subscribe ((res) => {
				if (res.status === 'success') {
					this.linkImage = environment.host_auth_download + res.data;
					let data       = this.authenticationService.getUser ();
					data['image']  = image;
					this.authenticationService.setUser (data);
					this.router.navigateByUrl ('/', {skipLocationChange : true}).then (() =>
						this.router.navigate (['/auth/profile'])
					);
					this.toasterService.pop ('success', appToaster.successHead, res.message);
				} else {
					this.toasterService.pop ('error', appToaster.errorHead, res.message);
				}
			});
	}
	
}
