import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgxPaginationModule } from "ngx-pagination";

import { AuthRoutingModule } from './auth-routing.module';
import { ThemeModule } from '../../core/theme.module';
import { SharedModule } from "../../shared/module/shared.module";

import { LoginComponent } from "./login/login.component";
import { ForgotPasswordComponent } from "./forgot-password/forgot-password.component";
import { ResetPasswordComponent } from "./reset-password/reset-password.component";
import { NotFoundComponent } from "./not-found/not-found.component";
import { ForbiddenComponent } from "./forbidden/forbidden.component";
import { ProfileComponent } from './profile/profile/profile.component';
import { HistoryComponent } from './profile/history/history.component';

import { UserComponent } from './users/user/user.component';
import { HistoryUserComponentComponent } from './users/history-user-component/history-user-component.component';
import { EditPasswordComponent } from './users/edit-password/edit-password.component';
import { EditUserComponent } from './users/edit-user/edit-user.component';

import { ResetPasswordUserComponent } from './profile/reset-password-user/reset-password-user.component';
import { EditAccountComponent } from './profile/edit-account/edit-account.component';
import { LoginAdminComponent } from './login-admin/login-admin.component';
import { CreateAgentCodeComponent } from './create-agent-code/create-agent-code.component';
import { ChangePasswordComponent } from './change-password/change-password.component';
import {PhoneMaskDirective} from '../../shared/directives/phone-mask.directive';


@NgModule ({
	imports      : [
		CommonModule,
		AuthRoutingModule,
		ThemeModule,
		SharedModule,
		NgxPaginationModule,
	],
	declarations : [
		AuthRoutingModule.components,
		LoginComponent,
		ForgotPasswordComponent,
		ResetPasswordComponent,
		NotFoundComponent,
		ForbiddenComponent,
		ProfileComponent,
		HistoryComponent,

		ResetPasswordUserComponent,
		EditAccountComponent,
		LoginAdminComponent,

		UserComponent,
		EditPasswordComponent,
		EditUserComponent,
		HistoryUserComponentComponent,
    CreateAgentCodeComponent,
    ChangePasswordComponent
	],
	entryComponents : [
		EditPasswordComponent,
		EditUserComponent,
		HistoryUserComponentComponent,
    ChangePasswordComponent
	],
  exports         : [
    ChangePasswordComponent
  ],
})
export class AuthModule {
}
