import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from "@angular/common/http";
import { Observable } from "rxjs";
import { environment } from "../../../../../environments/environment";

@Injectable ({
	providedIn : 'root'
})
export class BankService {
	
	constructor (private http: HttpClient) {
	}
	
	getAllBank (): Observable<any> {
		const url = environment.host_agent + 'get-all-bank';
		return this.http.get<any> (url);
	}
}
