import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl } from "@angular/forms";
import { ToasterService } from "angular2-toaster";
import { appToaster } from "../../../configs/app-toaster.config";
import { AuthenticationService } from "../../../core/authentication/authentication.service";
import { TranslateService } from "@ngx-translate/core";
import {CityService} from "../../../core/http/crm/city/city.service";

@Component ({
	selector    : 'app-nation-select',
	templateUrl : './nation-select.component.html',
	styleUrls   : ['./nation-select.component.css']
})
export class NationSelectComponent implements OnInit {
	@Input ('parentForm')
	public parentForm: FormControl;

	@Input ('placeholder')
	public placeholder: string = this.translateService.instant('select_country');

	@Input () multiple: boolean = false;

	@Input () clearable: boolean = true;

	@Input () classSelect: string = '';

	@Input () default: boolean = false;

	countryListValue: any;
	@Output ()
	countryListChange = new EventEmitter<string> ();

	@Input ()
	get countryList () {
		return this.countryListValue;
	}

	set countryList (val) {
		this.countryListValue = val;
		this.factoryListCityName (val);
		this.countryListChange.emit (this.countryListValue);
	}

	countryNameList: any = [];

	@Output ()
	selectItemChange = new EventEmitter<string> ();

	constructor (private cityService: CityService,
	             private toasterService: ToasterService,
	             private authenticationService: AuthenticationService,
	             private translateService: TranslateService) {

		if (this.default) {
          // country
          this.authenticationService.getCountryInfo ().subscribe ((countryId: any) => {
              let country = parseInt (countryId);
              console.log(this.parentForm);
              this.parentForm.setValue(country);
          });
        }

	}

	ngOnInit () {
		if (!this.countryList || Object.keys (this.countryList).length == 0) {
			this.getCountry ();
		}
	}

	getCountry () {
		this.cityService.getListCountry ()
			.subscribe ((res) => {
				if (res.status == "success") {
					this.countryList = res.data;
					this.factoryListCityName (this.countryList);
				} else {
					this.countryList = {};
					this.factoryListCityName (this.countryList);
					this.toasterService.pop ('error', appToaster.errorHead, res.message);

				}
			}, (error) => {
				this.countryList = {};
				this.factoryListCityName (this.countryList);
			});
	}

	factoryListCityName (data) {
		let arrListCountry = [];

		Object.keys (data).forEach (key => {
			arrListCountry.push ({key : parseInt (key), name : data[key]['name']});
		});
		this.countryNameList = arrListCountry;
	}

	selectItem (event) {
		this.selectItemChange.emit (event);
	}

}
