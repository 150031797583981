import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { FormControl } from "@angular/forms";
import { ListsPostService } from "../../../core/http/delivery/lists-post/lists-post.service";
import { ToasterService } from "angular2-toaster";
import { appToaster } from "../../../configs/app-toaster.config";
import { interval } from "rxjs";

@Component ({
	selector    : 'app-district-select',
	templateUrl : './district-select.component.html',
	styleUrls   : ['./district-select.component.css']
})
export class DistrictSelectComponent implements OnInit {

	@Input ('parentForm')
	public parentForm: FormControl;

	@Input () multiple: boolean = false;

	@Input () clearable: boolean = true;

	@Input ()
	classSelect: string = '';

	districtListValue: any;
	cityValue: number;

	isFirst: boolean = true;

	@Output ()
	districtListChange = new EventEmitter<any> ();

  countryCodeValue: string;

  @Input ()
  get countryCode () {
    return this.countryCodeValue;
  }

  set countryCode (val) {
    this.countryCodeValue = val;
  }


  @Input ()
	get cityId () {
		return this.cityValue;
	}

	set cityId (val) {
		this.cityValue = val;
		this.getListDistrict (val);
	}

	@Input ()
	get districtList () {
		return this.districtListValue;
	}

	set districtList (val) {
		this.districtListValue = val;
    this.factoryListDistrictName (val);
		this.districtListChange.emit (this.districtListValue);
	}
  typeUpdateValue: boolean = true;
  @Output ()
  typeUpdateChange         = new EventEmitter<any> ();
  @Input ()
  get typeUpdate () {
    return this.typeUpdateValue;
  }

  set typeUpdate (val) {
    this.typeUpdateValue = val;
    this.typeUpdateChange.emit (this.typeUpdateValue);
  }


  districtNameList = [];

	@Output ()
	selectItemChange = new EventEmitter<string> ();

	constructor (private listsPostService: ListsPostService,
	             private toasterService: ToasterService) {

	}

	ngOnInit () {
	}

  getListDistrict (cityId) {
    if (cityId) {
      if (!this.typeUpdate) {
        this.parentForm.setValue (null);
      }
      this.typeUpdateValue = false;
      this.getCountryInTheWorld (cityId);

    } else {
      this.parentForm.setValue (null);
      this.districtList = {};
      this.factoryListDistrictName (this.districtList);
    }
  }

  getCountryInTheWorld (cityId) {
    if(this.countryCodeValue) {
      this.listsPostService.getListCityByParentIdAndCountryCode (this.countryCodeValue, cityId)
        .subscribe ((res) => {
          if (res.status == "success") {
            this.districtList = res.data;
            this.factoryListDistrictName (this.districtList);
          } else {
            this.districtList = {};
            this.factoryListDistrictName (this.districtList);
            this.toasterService.pop ('error', appToaster.errorHead, res.message);

          }
        }, (error) => {
          this.districtList = {};
          this.factoryListDistrictName (this.districtList);

        });
    }
  }


  factoryListDistrictName (data) {
    let arrListDistrict = [];

    Object.keys (data).forEach (key => {
      arrListDistrict.push ({id : parseInt (key), name : data[key]});
    });
    this.districtNameList = arrListDistrict;
  }

	selectItem (event) {
		this.selectItemChange.emit (event);
	}

}
