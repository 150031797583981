import { Component, OnInit } from '@angular/core';
import { ToasterService } from "angular2-toaster";
import { NotificationService } from "../../../../core/http/agent/notification/notification.service";
import { AuthenticationService } from "../../../../core/authentication/authentication.service";
import { appToaster } from "../../../../configs/app-toaster.config";
import { KeyValue } from "@angular/common";
import { HistorySearchModel, IS_AGENT_ADMIN, IS_NOT_AGENT_ADMIN } from "../../../../shared/models/agent/history-search.model";
import { TimeSinceService } from "../../../../core/services/time-since.service";

@Component ({
	selector    : 'app-history',
	templateUrl : './history.component.html',
	styleUrls   : ['./history.component.css']
})
export class HistoryComponent implements OnInit {
	currentPage: number = 1;
	total: number       = 0;
	startShow: number   = 0;
	endShow: number     = 0;
	perPage: number     = 10;
	
	loadingSearch: boolean = false;
	loadingMore: boolean   = false;
	
	listLogsAction: any = {};
	
	constructor (private toasterService: ToasterService,
	             private timeSinceService: TimeSinceService,
	             private notificationService: NotificationService,
	             private authenticationService: AuthenticationService) {
	}
	
	ngOnInit () {
		this.loadingSearch = true;
		this.getListLogsAction ();
	}
	
	getListLogsAction () {
		let isAgentAdmin                           = this.authenticationService.isAgentAdmin ();
		let historySearchModel: HistorySearchModel = new HistorySearchModel ();
		historySearchModel.page                    = this.currentPage;
		historySearchModel.limit                   = this.perPage;
		if (isAgentAdmin) {
			historySearchModel.agentAdmin = IS_AGENT_ADMIN;
		} else {
			historySearchModel.agentAdmin = IS_NOT_AGENT_ADMIN;
		}
		this.notificationService.getListLogsAction (historySearchModel)
			.subscribe ((res) => {
				if (res.status === 'success') {
					this.loadingSearch  = false;
					this.loadingMore    = false;
					this.listLogsAction = res.data.logsAction;
					this.total          = res.data.total;
				} else {
					this.toasterService.pop ('error', appToaster.errorHead, res.message);
				}
			}, (err) => {
				this.toasterService.pop ('error', appToaster.errorHead, err.message);
			});
	}
	
	timeDifference (previous) {
		return this.timeSinceService.timeSince (previous);
	}
	
	indexOrderAsc = (akv: KeyValue<string, any>, bkv: KeyValue<string, any>): number => {
		const a = akv.value.timeUpdate;
		const b = bkv.value.timeUpdate;
		
		return a > b ? 1 : ( a > b ? -1 : 0 );
	}
	
	loadMore () {
		this.loadingMore = true;
		this.perPage += this.perPage;
		this.getListLogsAction ();
	}
	
}
