import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from "@angular/common/http";
import { Observable } from "rxjs";
import { environment } from "../../../../../environments/environment";

@Injectable ({
	providedIn : 'root'
})
export class ProjectService {
	
	constructor (private http: HttpClient) {
	}
	
	getProjectList (): Observable<any> {
		const url = environment.host_crm + 'get-app-name';
		return this.http.get<any> (url);
	}
}
