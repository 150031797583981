import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup } from "@angular/forms";
import { ProductService } from "../../../core/http/global/product/product.service";

@Component ({
	selector    : 'app-product-select',
	templateUrl : './product-select.component.html',
	styleUrls   : ['./product-select.component.css']
})
export class ProductSelectComponent implements OnInit {

	@Input ('parentForm')
	public parentForm: FormControl;
  @Input () removeValue: boolean = true;

	typeUpdateValue: boolean = true;
	@Output ()
	typeUpdateChange         = new EventEmitter<any> ();

	@Input ()
	get typeUpdate () {
		return this.typeUpdateValue;
	}

	set typeUpdate (val) {
		this.typeUpdateValue = val;
		this.typeUpdateChange.emit (this.typeUpdateValue);
	}

	appIdValue: string;

	@Input ()
	get appId () {
		return this.appIdValue;
	}

	set appId (val) {
		if (this.typeUpdate && this.removeValue) {
			this.parentForm.setValue (null);
		} else {
			this.typeUpdateValue = true;
		}
		this.getListProject (val);
		this.appIdValue = val;
	}

	@Input () multiple: boolean = false;

	@Input ()
	classSelect: string = '';

	productListValue: any;
	@Output ()
	productListChange = new EventEmitter<string> ();

	@Input ()
	get productList () {
		return this.productListValue;
	}

	set productList (val) {
		this.productListValue = val;
		this.productListChange.emit (this.productListValue);
	}

	@Output ()
	selectItemChange = new EventEmitter<string> ();

	constructor (private productService: ProductService) {

	}

	ngOnInit () {
	}

	getListProject (appId) {
		if (appId) {
			this.productService.getProductNameByAppId (appId)
				.subscribe (
					res => {
						if (res.status === 'success') {
							this.productList = res.data;
						} else {
							this.productList = {};
						}
					}, (error) => {
						this.productList = {};
					})
		} else {
			this.productList = {};
		}
	}

	selectItem (event) {
		this.selectItemChange.emit (event);
	}
}
