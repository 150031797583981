import {Component, OnInit, Input, Inject, LOCALE_ID} from "@angular/core";
import {TranslateService} from "@ngx-translate/core";
import {AuthenticationService} from "src/app/core/authentication/authentication.service";
import {DEFAULT_LANGUAGE} from "src/app/shared/models/base.model";

@Component({
    selector   : "app-select-language",
    templateUrl: "./select-language.component.html",
    styleUrls  : ["./select-language.component.css"],
})
export class SelectLanguageComponent implements OnInit {
    @Input() isDisable: boolean = false;
    switchLang                  = DEFAULT_LANGUAGE;
    locale                      = navigator.language;
    selectorItem: any           = [
        {key: "vn", value: "VN", avatar: "vietnam.svg", country: 84},
        {key: "en", value: "EN", avatar: "united-states-of-america.svg", country: 1},
        {key: "id", value: "ID", avatar: "indonesia.svg", country: 62},
        {key: "th", value: "TH", avatar: "thailand.svg", country: 66},
    ];

    url: string = ".../../../../assets/images/logo/flag/";

    constructor(
        private authenticationService: AuthenticationService,
        private translate: TranslateService
    ) {
        const valueLanguage =
                  this.authenticationService.getData("language") || DEFAULT_LANGUAGE;
        this.switchLang     = valueLanguage;
    }

    ngOnInit() {
    }

    onLanguage(key, country) {
        this.authenticationService.setData("language", key);
        this.authenticationService.setData("country", country);
        this.switchLang = key;
        this.translate.use(this.switchLang);
    }
}
