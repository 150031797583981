import {Component, Inject, LOCALE_ID, OnInit} from '@angular/core';
import { FormControl, FormGroup, Validators, FormBuilder } from '@angular/forms';
import { AuthenticationService } from '../../../core/authentication/authentication.service';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { ToasterService } from 'angular2-toaster';
import 'style-loader!angular2-toaster/toaster.css';
import { appSettings } from '../../../configs/app-settings.config';
import { appToaster } from '../../../configs/app-toaster.config';
import { EMAIL_REGEX } from "../../../shared/models/base.model";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { PopupPageComponent } from "../../../shared/components/popup-page/popup-page.component";
import { AuthService, GoogleLoginProvider } from 'angular-6-social-login';
import { PageService } from "../../../core/http/agent/page/page.service";
import {LANGUAGE_EN, LANGUAGE_VN, LOCALE_VN} from '../../../shared/models/auth/login.model';
import {TranslateService} from '@ngx-translate/core';

@Component ({
	selector    : 'app-login',
	templateUrl : './login.component.html',
	styleUrls   : ['./login.component.css']
})
export class LoginComponent implements OnInit {

  readonly LANGUAGE_VN = LANGUAGE_VN;
  readonly LANGUAGE_EN = LANGUAGE_EN;
  language: any;

	loginForm: FormGroup;
	isSubmit: boolean = false;
	appSettings: any;
	message: string   = '';
	initAuthGoogle    = false;
	loadingLogin      = false;

	constructor (private router: Router,
	             private route: ActivatedRoute,
	             private fb: FormBuilder,
	             private toasterService: ToasterService,
	             private modalService: NgbModal,
	             private authenticationService: AuthenticationService,
	             private socialAuthService: AuthService,
	             private pageService: PageService,
               @Inject(LOCALE_ID) protected localeId: string,
               private translate: TranslateService
               ) {
	  if (!this.authenticationService.getData("language")) {
      if (localeId == LOCALE_VN) {
        this.translate.use (LANGUAGE_VN);
        this.authenticationService.setData("language", LANGUAGE_VN)
      } else {
        this.translate.use (LANGUAGE_EN);
        this.authenticationService.setData("language", LANGUAGE_EN)
      }
    } else {
	    this.translate.use(this.authenticationService.getData('language'))
    }
    this.language = this.authenticationService.getData("language")
	}

	ngOnInit () {
		this.appSettings = appSettings;
		this.authenticationService.logout ();
		this.createLoginForm ();
		this.socialAuthService.authState.subscribe (res => {
			if (res) {
				this.initAuthGoogle = true;
			}
		});
	}

	createLoginForm () {
		this.loginForm = this.fb.group ({
			'user_name'    : new FormControl ('', [
				Validators.required,
				Validators.maxLength (250)
			]),
			'password' : new FormControl ('', [
				Validators.required,
				Validators.minLength (6),
				Validators.maxLength (20)
			])
		});
	}

	onSubmit (): boolean {
		this.isSubmit = true;

		if (this.loginForm.invalid) {
			this.toasterService.pop ('error', appToaster.apiErrorHead, 'Vui lòng kiểm tra lại thông tin đăng nhập');
			return this.isSubmit = false;
		} else {

			this.authenticationService.login (this.loginForm.value)
				.subscribe ((res) => {
						this.isSubmit = false;
						if (res.status === 'fail') {
							this.toasterService.pop ('error', appToaster.apiErrorHead, res.message);
						} else {
							this.toasterService.pop ('success', appToaster.successHead, res.message);
							this.getPopupDisplay ();
						}
					}, (error) => {
						this.isSubmit = false;
					}
				);

		}
	}

	signInWithGoogle () {
		let socialPlatformProvider = GoogleLoginProvider.PROVIDER_ID;

		this.socialAuthService.signIn (socialPlatformProvider).then (
			(userData) => {
				let token = userData['idToken'];
				this.loginWithGoogle (token);
			}
		);
	}

	loginWithGoogle (token) {
		this.loadingLogin = true;
		this.authenticationService.loginWithGoogle (token)
			.subscribe ((res) => {
					this.loadingLogin = false;
					if (res.status === 'fail') {
						this.message = res.message;
						this.toasterService.pop ('error', appToaster.apiErrorHead, res.message);
					} else {
						this.toasterService.pop ('success', appToaster.successHead, res.message);
						this.getPopupDisplay ();
					}
				}, (error) => {
					this.loadingLogin = false;
				}
			);
	}

	getPopupDisplay () {
		let country_code = localStorage.getItem("country");
		this.pageService.getPopupDisplay (country_code)
			.subscribe ((res) => {
				if (res.status == 'success') {
					let listPopupDisplay = res.data;
					if (listPopupDisplay.length > 0) {
						const modalRef                              = this.modalService.open (PopupPageComponent, {size : 'lg', backdrop : 'static'});
						modalRef.componentInstance.listPopupDisplay = listPopupDisplay;
					}
				}
			});
	}

	changeLanguage(language) {
    this.translate.use (language);
    this.authenticationService.setData("language", language);
    window.location.reload()
  }
}
