import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from "@angular/common/http";
import { Observable } from "rxjs";
import { environment } from "../../../../../environments/environment";

@Injectable({
    providedIn: 'root'
})
export class PageService {
    constructor(private http: HttpClient) {
    }

    getPopupDisplay(country_code): Observable<any> {
        const url = environment.host_agent + 'get-popup-display';
	    let params = new HttpParams();
	    params = params.append('country_code', country_code);
	    return this.http.get<any>(url, {params: params});
    }

    getDetailPage(id): Observable<any> {
        const url = environment.host_agent + 'get-detail-page';
        let params = new HttpParams();
        params = params.append('id', id.toString());

        return this.http.get<any>(url, {params: params});
    }

}
