import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from "@angular/common/http";
import { Observable } from "rxjs";
import { environment } from "../../../../../environments/environment";

@Injectable ({
	providedIn : 'root'
})
export class PackageProductService {
	
	constructor (private http: HttpClient) {
	}
	
	getListSerialByProductId (productId, agentId): Observable<any> {
		const url  = environment.host_agent + 'get-list-serial-by-product-id';
		let params = new HttpParams ();
		params     = params.append ('product_id', productId);
		params     = params.append ('agent_id', agentId.toString ());
		
		return this.http.get<any> (url, {params : params});
	}
}
