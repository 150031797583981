import * as moment from "moment";

export const EMAIL_REGEX =
    "[a-z0-9!#$%&'*+/=?^_`{|}~.-]+@[a-z0-9]([a-z0-9]*[a-z0-9])?(.[a-z0-9]([a-z0-9-]*[a-z0-9])?)*";
export const PASSWOR_REGEX = "[a-zA-Z0-9!@#$%&'*+/=?^_`{|}~.-]{1,20}";
export const PHONE_REGEX = "((00|\\+)[0-9]{2,3}){0,1}[0-9]{4,14}";
export const AGENT_CODE_REGEX = "[a-zA-Z0-9]{1,45}";
export const NUMBER_REGEX = "^[0-9]*$";
export const CHARACTERS_REGEX = "[a-zA-Z ]*";

export const NEW = 0;
export const EDIT = 1;
export const DETAIL = 2;
export const COPY = 3;

export const VIET_NAM = 84;

export const IS_NOT_ACTIVE = 0;
export const IS_ACTIVE = 1;
export const STATUS_LIST = [
    { id: IS_ACTIVE, name: "activated" },
    { id: IS_NOT_ACTIVE, name: "not_activate" },
];

export const RANGES: any = {
    Today: [moment(), moment()],
    Yesterday: [moment().subtract(1, "days"), moment().subtract(1, "days")],
    "Last 7 Days": [moment().subtract(6, "days"), moment()],
    "Last 30 Days": [moment().subtract(29, "days"), moment()],
    "This Month": [moment().startOf("month"), moment().endOf("month")],
    "Last Month": [
        moment().subtract(1, "month").startOf("month"),
        moment().subtract(1, "month").endOf("month"),
    ],
};

export const LOCALE_TIME: any = {
    format: "DD/MM/YYYY HH:mm",
};

export const LOCALE: any = {
    format: "DD/MM/YYYY",
};

export const TYPE_DATE_DAY = 0;
export const TYPE_DATE_WEEK = 1;
export const TYPE_DATE_MONTH = 2;
export const LIST_DATE = [
    { type: "0", name: "date" },
    { type: "1", name: "week" },
    { type: "2", name: "month" },
];

export const QUANTITYSHOWLIST = [
    { id: 5, name: "5" },
    { id: 10, name: "10" },
    { id: 15, name: "15" },
    { id: 20, name: "20" },
    { id: 25, name: "25" },
];

export const QUANTITYSHOWLISTMONTH = [
    { id: 3, name: "3_months" },
    { id: 6, name: "6_months" },
    { id: 9, name: "9_months" },
    { id: 12, name: "12_months" },
];

export const QUANTITYSHOWLISTAll = [
    { id: 10, name: "10" },
    { id: 25, name: "25" },
    { id: 50, name: "50" },
    { id: 100, name: "100" },
    { id: 500, name: "500" },
    { id: 99999, name: "tất cả" },
];

export const ARRAY_COLOR = [
    "rgb(121, 249, 153)",
    "rgb(156, 156, 156)",
    "rgb(33, 206, 206)",
    "rgb(206, 119, 33)",
    "rgb(206, 100, 197)",
    "rgb(33, 33, 206)",
    "rgb(223, 0, 41)",
    "rgb(105, 105, 105)",
    "rgb(100, 20, 206)",
    "rgb(40, 150, 206)",
    "rgb(206, 119, 33)",
    "rgb(45, 80, 206)",
];

export const BG_COLOR = [
    "rgb(121, 249, 153, 0.5)",
    "rgb(156, 156, 156, 0.5)",
    "rgb(33, 206, 206, 0.5)",
    "rgb(206, 119, 33, 0.5)",
    "rgb(206, 100, 197, 0.5)",
    "rgb(33, 33, 206, 0.5)",
    "rgba(223, 0, 41, 0.5)",
    "rgb(105, 105, 105, 0.5)",
    "rgb(100, 20, 206, 0.5)",
    "rgb(40, 150, 206, 0.5)",
    "rgb(206, 119, 33, 0.5)",
    "rgb(45, 80, 206, 0.5)",
];
export const NOT_ACTIVATED = 0;
export const IS_ACTIVATED = 1;
export const IS_LOCK = 2;
export const STOP_WORKING = 3;

export const STATUS_LIST_AGENT = [
  { id: NOT_ACTIVATED, name: 'not_activate', disabled: false },
  { id: IS_ACTIVATED, name: 'active', disabled: false },
  { id: IS_LOCK, name: 'temporary_lock', disabled: false },
  { id: STOP_WORKING, name: 'non_active', disabled: false },
];

export const DEFAULT_LANGUAGE = 'vn';
export const DEFAULT_COUNTRY  = 84;
