export const IS_NOT_ACTIVE = 0;
export const IS_ACTIVE     = 1;
export const STATUS_LIST   = [
	{'id' : IS_ACTIVE, 'name' : 'Hoạt động'},
	{'id' : IS_NOT_ACTIVE, 'name' : 'Dừng hoạt động'}
];

export const OPERATOR       = 23;
export const OPERATOR_IN    = 24;
export const OPERATOR_ADMIN = 25;
export const MARKETING      = 16;
export const SALES          = 15;
export const SALES_ADMIN    = 13;
// export const MANAGE                = 1;
// export const AFFILIATE             = 18;
export const CUSTOMER_CARE        = 17;
export const MANAGE_CUSTOMER_CARE = 27;
export const LEAD_CUSTOMER_CARE   = 28;
export const S1_CUSTOMER_CARE     = 29;
export const S2_CUSTOMER_CARE     = 30;
export const LEAD_MARKETING       = 31;
export const INC                  = 32;
export const SALE_VH_MANAGE       = 33;
export const SALE_VH_LEAD         = 34;
export const SALE_VH_TELESALES    = 35;
export const SALE_VH_TELEMAR      = 36;
export const SALE_PT_MANAGE       = 37;
export const SALE_PT_TELESALES    = 38;
export const SALE_OS_MANAGE       = 39;
export const SALE_OS_LEAD         = 40;
export const SALE_OS_TELEMAR      = 41;
export const EXPORT_DATA          = 42;
export const ORDER_DUPLICATE      = 43;
// export const AGENT_ADMIN           = 44;
export const AGENT_NV_COD         = 45;
// export const AGENT_NV_EMAIL        = 46;

export const AFFI_STAFF       = "AFFI_STAFF";
export const MANAGE           = "MANAGE";
export const AFFILIATE        = "AFFILIATE";
export const AGENT_ADMIN      = "AGENT_ADMIN";
export const AGENT_NV_EMAIL   = "AGENT_NV_EMAIL";
export const AGENT_AFFI_STAFF = "AGENT_AFFI_STAFF";


export class Role {
	id ?: number;
	role: string;
}
