import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from "@angular/forms";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ToasterService } from "angular2-toaster";
import { appToaster } from "../../../../configs/app-toaster.config";
import { interval } from "rxjs";

import { AuthenticationService } from "../../../../core/authentication/authentication.service";
import { UserAgentService } from "../../../../core/http/agent/user-agent/user-agent.service";

import { EditUserComponent } from "../edit-user/edit-user.component";
import { EditPasswordComponent } from "../edit-password/edit-password.component";
import { HistoryUserComponentComponent } from "../history-user-component/history-user-component.component";
import { ConfirmDialogComponent } from "../../../../shared/components/confirm-dialog/confirm-dialog.component";

import { UserSearchModel } from "../../../../shared/models/auth/user-search.model";
import { EDIT, NEW } from "../../../../shared/models/base.model";
import { IS_ACTIVE, IS_NOT_ACTIVE, STATUS_LIST } from "../../../../shared/models/auth/user.model";
import { VIEW_AFFI, VIEW_DEPOSIT, VIEW_HOLD_CARD } from "../../../../shared/models/auth/router.model";
import {TranslateService} from '@ngx-translate/core';


@Component ({
	selector    : 'app-user',
	templateUrl : './user.component.html',
	styleUrls   : ['./user.component.css']
})
export class UserComponent implements OnInit {
	readonly VIEW_AFFI      = VIEW_AFFI;
	readonly VIEW_HOLD_CARD = VIEW_HOLD_CARD;
	readonly VIEW_DEPOSIT   = VIEW_DEPOSIT;
	readonly EDIT           = EDIT;
	readonly NEW            = NEW;

	statusList: any = STATUS_LIST;
	IS_ACTIVE       = IS_ACTIVE;

	agentId: any;

	userSearchForm: FormGroup;

	listUserAgent: any = [];
	listRoleAgent: any = {};

	currentPage: number = 1;
	total: number       = 0;
	startShow: number   = 0;
	endShow: number     = 0;
	perPage: number     = 10;

	loadingSearch: boolean = false;

	view: number;

	constructor (private fb: FormBuilder,
	             private modalService: NgbModal,
	             private toasterService: ToasterService,
	             private authenticationService: AuthenticationService,
	             private userAgentService: UserAgentService,
               private translate: TranslateService) {
	  this.translateStatus();
	  this.changeLanguage();
	}

	ngOnInit () {
		this.agentId = this.authenticationService.getAgentId ();
		this.view    = this.authenticationService.getView ();

		this.createUserSearchForm ();
		this.getRoleAgent ();
		this.search ();
		this.changeLanguage();
	}

	createUserSearchForm () {
		this.userSearchForm = this.fb.group ({
			'fullname' : [null],
			'email'    : [null],
			'status'   : [null]
		});
	}

	getRoleAgent () {
		this.userAgentService.getListRoleAgent ()
			.subscribe ((res) => {
				if (res.status === 'success') {
					this.listRoleAgent = res.data;
				} else {
					this.listRoleAgent = {};
					this.toasterService.pop ('error', appToaster.errorHead, res.message);
				}
			}, (error) => {
				this.listRoleAgent = {};
			});
	}

	search () {
		this.currentPage = 1;
		this.searchListUserAgent ();
	}

	searchListUserAgent () {
		let userSearchModel: UserSearchModel = new UserSearchModel ();
		userSearchModel.page                 = this.currentPage;
		userSearchModel.limit                = this.perPage;
		userSearchModel.fullname             = this.userSearchForm.get ('fullname').value;
		userSearchModel.email                = this.userSearchForm.get ('email').value;
		userSearchModel.status               = this.userSearchForm.get ('status').value;
		userSearchModel.agent_id             = this.agentId;

		this.loadingSearch         = true;
		let subscribeListUserAgent = this.userAgentService.getListUserAgent (userSearchModel)
			.subscribe ((res) => {
				this.loadingSearch = false;
				if (res.status === 'success') {
					this.listUserAgent = res.data.data;
					this.total         = res.data.total;
				} else {
					this.listUserAgent = [];
					this.total         = 0;
					this.toasterService.pop ('error', appToaster.errorHead, res.message);
				}
			}, (error) => {
				this.listUserAgent = [];
				this.total         = 0;
			});

		const observableTotal = interval ();

		let subscribeTotal = observableTotal.subscribe (() => {
			this.startShow = ( this.currentPage - 1 ) * this.perPage + 1;
			this.endShow   = this.currentPage * this.perPage;
			if (this.total < this.endShow) {
				this.endShow = this.total;
			}
		});
		subscribeListUserAgent.add (subscribeTotal);

	}

	changePage ($event) {
		this.currentPage = $event;
		this.searchListUserAgent ();
	}

	updateStatus (id, status, name) {
		if (id == null || status == null) {
			this.toasterService.pop ('error', appToaster.errorHead, "Lỗi Tham số");
		}

		const modalRef = this.modalService.open (ConfirmDialogComponent, {size : 'lg', windowClass : 'modal-xl', backdrop : 'static'});
		if (status == IS_ACTIVE) {
			status                                  = IS_NOT_ACTIVE;
			modalRef.componentInstance.modalHeader  = this.translate.instant("non_active");
			modalRef.componentInstance.modalContent = this.translate.instant("employee_message_confirm_non_active") + " <samp class='text-danger'>" + name + "</samp>";
		} else if (status == IS_NOT_ACTIVE) {
			status                                  = IS_ACTIVE;
			modalRef.componentInstance.modalHeader  = this.translate.instant("active");
			modalRef.componentInstance.modalContent = this.translate.instant("employee_message_confirm_active") + "<samp class='text-danger'>" + name + "</samp>";
		}

		modalRef.result.then ((result) => {
			if (result) {
				this.userAgentService.updateStatusUser (id, status)
					.subscribe ((res) => {
						if (res.status === 'success') {
							this.searchListUserAgent ();
							this.toasterService.pop ('success', appToaster.successHead, res.message);
						} else {
							this.searchListUserAgent ();
							this.toasterService.pop ('error', appToaster.errorHead, res.message);
						}
					});
			} else {
				this.searchListUserAgent ();
			}
		}, (reason) => {
		});
	}

	editUser (type, user) {
		const modalRef = this.modalService.open (EditUserComponent, {size : 'lg', windowClass : 'modal-xl', backdrop : 'static'});

		if (type === NEW) {
			modalRef.componentInstance.modalHeader = this.translate.instant("add_new_employee");
		}
		if (type === EDIT) {
		  console.log(this.translate.instant("user_update"))
			modalRef.componentInstance.modalHeader = this.translate.instant("user_update") + " <samp class='text-danger'>" + user.fullname + "</samp>";
			modalRef.componentInstance.user        = user;
		}
		modalRef.componentInstance.agentId  = this.agentId;
		modalRef.componentInstance.listRole = this.listRoleAgent;

		modalRef.result.then ((result) => {
			if (result) {
				this.searchListUserAgent ();
			}
		}, (reason) => {
		});
	}

	editPass (user) {
		const modalRef = this.modalService.open (EditPasswordComponent, {size : 'lg', windowClass : 'modal-xl', backdrop : 'static'});

		modalRef.componentInstance.modalHeader = this.translate.instant("update_user_pass") + " <samp class='text-danger'>" + user.fullname + "</samp>";
		modalRef.componentInstance.id          = user.id;
		modalRef.result.then ((result) => {
			if (result) {
				this.searchListUserAgent ();
			}
		}, (reason) => {
		});
	}

	showHistory (id, email) {
		const modalRef = this.modalService.open (HistoryUserComponentComponent, {size : 'lg', windowClass : 'modal-xl', backdrop : 'static'});

		modalRef.componentInstance.email = email;
		modalRef.componentInstance.id    = id;
		modalRef.result.then ((result) => {
			if (result) {
				this.searchListUserAgent ();
			}
		}, (reason) => {
		});
	}

  translateStatus () {
    let status       = [];
    let statusObject = {};
    this.statusList.forEach ((element) => {
      statusObject = {
        id   : element.id,
        name : this.translate.instant (element.name)
      };
      status.push (statusObject);
    });

    this.statusList = status;
  }

  changeLanguage() {
    this.translate.onLangChange.subscribe (lang => {
      this.translateStatus();
    });
  }
}
