export const LOCALE_VN = "vi_VN";
export const LANGUAGE_VN = "vn";
export const LANGUAGE_EN = "en";

export interface Login {
	username: string;
	password: string;
	remember?: boolean;
}

