import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { appSettings } from '../../../configs/app-settings.config';
import { appToaster } from '../../../configs/app-toaster.config';
import { ToasterService } from 'angular2-toaster';
import { UserService } from '../../../core/http/global/user/user.service';

@Component ({
	selector    : 'app-reset-password',
	templateUrl : './reset-password.component.html',
	styleUrls   : ['./reset-password.component.css']
})
export class ResetPasswordComponent implements OnInit {
	resetPasswordForm: FormGroup;
	appSettings = appSettings;
	isSubmit: boolean;
	token: string;
	status: boolean;
	message: string;

	constructor (
		private router: Router,
		private activatedRoute: ActivatedRoute,
		private fb: FormBuilder,
		private userService: UserService,
		private toasterService: ToasterService,
	) {
	}


	ngOnInit () {
		this.activatedRoute.params.subscribe (params => {
			this.token = params['token'];
			this.userService.verifyToken (this.token)
				.subscribe ((res) => {
					if (res.status === 'success') {
						this.status = true;
					} else {
						this.status  = false;
						this.message = res.message;
					}
				});
		});

		this.createResetPasswordForm ();
	}

	createResetPasswordForm () {
		this.resetPasswordForm = this.fb.group ({
			password        : [
				'',
				[
					Validators.required,
					Validators.minLength (6),
					Validators.maxLength (20),
				]
			],
			confirmPassword : [
				'',
				[
					Validators.required,
					Validators.minLength (6),
					Validators.maxLength (20),
				]
			],
		});
	}

	onSubmit (): boolean {
		this.isSubmit = true;
		if (this.resetPasswordForm.invalid) {
			return false;
		} else {
			let new_password = this.resetPasswordForm.get ('confirmPassword').value;
			this.userService.recoveryPassword (new_password, this.token)
				.subscribe ((res) => {
					if (res.status === 'success') {
						this.toasterService.pop ('success', appToaster.successHead, res.message);
						this.router.navigate (['/auth/login']);
					} else {
						this.toasterService.pop ('error', appToaster.errorHead, res.message);
					}
				});
		}
	}


}
