import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { ToasterService } from "angular2-toaster";
import { interval } from "rxjs";

import { UserAgentService } from "../../../../core/http/agent/user-agent/user-agent.service";

@Component ({
	selector    : 'app-history-user-component',
	templateUrl : './history-user-component.component.html',
	styleUrls   : ['./history-user-component.component.css']
})
export class HistoryUserComponentComponent implements OnInit {
	
	@Input () id: number;
	@Input () email: string;
	
	listUsers: any         = {};
	listHistory: any       = [];
	loadingSearch: boolean = false;
	
	currentPage: number = 1;
	total: number       = 0;
	startShow: number   = 0;
	endShow: number     = 0;
	perPage: number     = 10;
	
	constructor (
		public activeModal: NgbActiveModal,
		public userAgentService: UserAgentService,
		public toasterService: ToasterService
	) {
	}
	
	ngOnInit () {
		this.getListHistoryUpdate ();
	}
	
	getListHistoryUpdate () {
		this.loadingSearch   = true;
		let subscribeHistory = this.userAgentService.getListHistoryActivity (this.id, this.currentPage, this.perPage)
			.subscribe ((res) => {
				this.loadingSearch = false;
				if (res.status === 'success') {
					this.listHistory = res.data.listHistory;
					this.listUsers   = res.data.listUsers;
					this.total       = res.data.total;
				} else {
					this.listHistory = [];
					this.listUsers   = {};
					this.total       = 0;
				}
			}, (error) => {
				this.loadingSearch = false;
				this.listHistory   = [];
				this.listUsers     = {};
				this.total         = 0;
			});
		
		const observableTotal = interval ();
		
		let subscribeTotal = observableTotal.subscribe (() => {
			this.startShow = ( this.currentPage - 1 ) * this.perPage + 1;
			this.endShow   = this.currentPage * this.perPage;
			if (this.total < this.endShow) {
				this.endShow = this.total;
			}
		});
		subscribeHistory.add (subscribeTotal);
	}
	
	changePage (event) {
		this.currentPage = event;
		this.getListHistoryUpdate ();
	}
	
	close () {
		this.activeModal.close (false);
	}
}
