import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl } from "@angular/forms";
import { ProductService } from "../../../core/http/global/product/product.service";
import { BankBranchService } from "../../../core/http/agent/bank-branch/bank-branch.service";

@Component ({
	selector    : 'app-bank-branch-select',
	templateUrl : './bank-branch-select.component.html',
	styleUrls   : ['./bank-branch-select.component.css']
})
export class BankBranchSelectComponent implements OnInit {
	
	
	@Input ('parentForm')
	public parentForm: FormControl;
	
	
	typeUpdateValue: boolean = true;
	@Output ()
	typeUpdateChange         = new EventEmitter<any> ();
	
	@Input ()
	get typeUpdate () {
		return this.typeUpdateValue;
	}
	
	set typeUpdate (val) {
		this.typeUpdateValue = val;
		this.typeUpdateChange.emit (this.typeUpdateValue);
	}
	
	bankIdValue: string;
	
	@Input ()
	get bankId () {
		return this.bankIdValue;
	}
	
	set bankId (val) {
		if (this.typeUpdate) {
			this.parentForm.setValue (null);
		} else {
			this.typeUpdateValue = true;
		}
		this.getListBankBranch (val);
		this.bankIdValue = val;
	}
	
	@Input () multiple: boolean = false;
	
	@Input ()
	classSelect: string = '';
	
	bankBranchListValue: any;
	@Output ()
	bankBranchListChange = new EventEmitter<any> ();
	
	@Input ()
	get bankBranchList () {
		return this.bankBranchListValue;
	}
	
	set bankBranchList (val) {
		this.bankBranchListValue = val;
		this.getBankBranchName (val);
		this.bankBranchListChange.emit (this.bankBranchListValue);
	}
	
	bankBranchName: any = [];
	
	@Output ()
	selectItemChange = new EventEmitter<string> ();
	
	constructor (private bankBranchService: BankBranchService) {
	
	}
	
	ngOnInit () {
	}
	
	getListBankBranch (bankId) {
		this.bankBranchService.getListBankBranchByBank (bankId)
			.subscribe (
				res => {
					if (res.status === 'success') {
						this.bankBranchList = res.data;
						this.getBankBranchName (this.bankBranchList);
					} else {
						this.bankBranchList = {};
						this.getBankBranchName (this.bankBranchList);
					}
				});
	}
	
	getBankBranchName (bankBranchList) {
		let bankBranchName = [];
		if(bankBranchList && Object.keys (bankBranchList).length > 0){
			Object.keys (bankBranchList).forEach ((key) => {
				bankBranchName.push ({
					id   : bankBranchList[key]['id'],
					name : bankBranchList[key]['name']
				});
			});
		}
		this.bankBranchName = bankBranchName;
	}
	
	selectItem (event) {
		this.selectItemChange.emit (event);
	}
	
}
