import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl } from "@angular/forms";
import { BankService } from "../../../core/http/agent/bank/bank.service";
import { appToaster } from "../../../configs/app-toaster.config";
import { UserAgentService } from "../../../core/http/agent/user-agent/user-agent.service";
import { ToasterService } from "angular2-toaster";
import { AuthenticationService } from "../../../core/authentication/authentication.service";

@Component({
  selector: 'app-staff-select',
  templateUrl: './staff-select.component.html',
  styleUrls: ['./staff-select.component.css']
})
export class StaffSelectComponent implements OnInit {
  @Input ('parentForm')
  public parentForm: FormControl;

  @Input () multiple: boolean = false;

  @Input () clearable: boolean = true;

  @Input ()
  classSelect: string = '';
  @Input ()
  placeholder: string = 'Staff';

  listUserAgentValue: any;
  @Output ()
  listUserAgentChange = new EventEmitter<any> ();

  @Input ()
  get listUserAgent () {
    return this.listUserAgentValue;
  }

  set listUserAgent (val) {
    this.listUserAgentValue = val;
    this.listUserAgentChange.emit (this.listUserAgentValue);
  }

  @Output ()
  selectItemChange = new EventEmitter<string> ();
  agentId: any;
  constructor (
    private userAgentService: UserAgentService,
    private toasterService: ToasterService,
    private authenticationService: AuthenticationService,
    ) {
    this.agentId = this.authenticationService.getAgentId ();
  }

  ngOnInit () {
    if (Object.keys (this.listUserAgent).length == 0) {
      this.getListUserAgent ();
    }
  }

  getListUserAgent () {
    let agentId = this.agentId;
    this.userAgentService.getAllUserAgent (agentId)
      .subscribe ((res) => {
        if (res.status === 'success') {
          this.listUserAgent = res.data;
        } else {
          this.listUserAgent = {};
          this.toasterService.pop ('error', appToaster.errorHead, res.message);
        }
      }, (error) => {
        this.listUserAgent = {};
      });
  }

  selectItem (event) {
    this.selectItemChange.emit (event);
  }
}
