export const IS_NOT_ACTIVE = 0;
export const IS_ACTIVE     = 1;
export const STATUS_LIST   = [{'id' : IS_ACTIVE, 'name' : 'active'}, {'id' : IS_NOT_ACTIVE, 'name' : 'non_active'}];

export class User {
	public agent_id?: any;
	public id?: string;
	public email?: string;
	public fullname?: string;
	public password?: string;
	public role?: any;
	public roleName?: string;
	public phone?: string;
	public listRoleName?: any;
}
