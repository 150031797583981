import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ToasterService } from "angular2-toaster";
import { Router } from "@angular/router";
import { LogValidationErrorsService } from "../../../../core/services/log-validation-errors.service";

import { AuthenticationService } from "../../../../core/authentication/authentication.service";
import { UserAgentService } from "../../../../core/http/agent/user-agent/user-agent.service";

import { User } from "../../../../shared/models/auth/user.model";
import { EMAIL_REGEX } from "../../../../shared/models/base.model";
import { appToaster } from "../../../../configs/app-toaster.config";

@Component ({
	selector    : 'app-edit-account',
	templateUrl : './edit-account.component.html',
	styleUrls   : ['./edit-account.component.css']
})
export class EditAccountComponent implements OnInit {
	
	formErrors = {
		'name'  : '',
		'email' : ''
	};
	
	validationMessages = {
		'name'  : {
			'required'  : 'Fullname  is required.',
			'minlength' : 'Fullname at least 4 characters.',
			'maxlength' : 'Fullname  must be less than 200 characters.',
		},
		'email' : {
			'required'  : 'Email is required.',
			'pattern'   : 'Email validators format.',
			'minlength' : 'Email at least 4 characters.',
			'maxlength' : 'Email must be less than 200 characters.',
		}
	};
	
	@Input () user: any;
	
	accountForm: FormGroup;
	
	loadingSave: boolean = false;
	
	isEdit: boolean = true;
	
	constructor (private fb: FormBuilder,
	             private modalService: NgbModal,
	             private router: Router,
	             private toasterService: ToasterService,
	             private userAgentService: UserAgentService,
	             private authenticationService: AuthenticationService,
	             private logValidationErrorsService: LogValidationErrorsService) {
	}
	
	ngOnInit () {
		this.createAccountForm ();
		this.setInfoAccount ();
	}
	
	createAccountForm () {
		this.accountForm = this.fb.group ({
			name  : [
				null,
				[
					Validators.required,
					Validators.minLength (4),
					Validators.maxLength (200),
				]
			],
			email : [
				null,
				[
					Validators.required,
					Validators.minLength (4),
					Validators.maxLength (200),
					Validators.pattern (EMAIL_REGEX)
				]
			]
		});
		
		this.accountForm.valueChanges.subscribe ((data) => {
			this.logValidationErrorsService.logValidationErrors (this.accountForm, this.formErrors, this.validationMessages);
		});
	}
	
	setInfoAccount () {
		this.accountForm.patchValue ({
			name  : this.user.fullname,
			email : this.user.email,
		});
		
		this.accountForm.disable ();
	}
	
	save () {
		let user: User = new User ();
		user.id        = this.user.id;
		user.fullname  = this.accountForm.get ('name').value;
		
		this.loadingSave = true;
		this.userAgentService.editInfoUserById (user)
			.subscribe ((res) => {
				this.loadingSave = false;
				if (res.status === 'success') {
					this.isEdit      = true;
					this.accountForm.disable ();
					
					let dataUser         = this.authenticationService.getUser ();
					dataUser['fullname'] = user.fullname;
					this.authenticationService.setUser (dataUser);
					this.router.navigateByUrl ('/', {skipLocationChange : true}).then (() =>
						this.router.navigate (['/auth/profile'])
					);
					
					this.toasterService.pop ('success', appToaster.successHead, res.message);
				} else {
					
					this.toasterService.pop ('error', appToaster.errorHead, res.message);
				}
			}, (error) => {
				this.loadingSave = false;
			});
	}
	
	edit () {
		this.isEdit = false;
		this.accountForm.enable ();
		this.accountForm.get ('email').disable ({onlySelf : true});
	}
}
