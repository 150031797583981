import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl } from "@angular/forms";
import { GroupUserService } from "../../../core/http/global/group-user/group-user.service";
import { AuthenticationService } from "../../../core/authentication/authentication.service";
import { LIST_GROUP_BY_AGENT } from "../../models/agent/group-user.model";

@Component({
  selector: 'app-group-user-select',
  templateUrl: './group-user-select.component.html',
  styleUrls: ['./group-user-select.component.css']
})
export class GroupUserSelectComponent implements OnInit {
	@Input ('parentForm')
	public parentForm: FormControl;
	
	@Input () multiple: boolean = false;
	
	@Input () disabled: boolean = false;
	
	@Input () clearable: boolean = true;
	
	@Input() checkGetData: boolean = true;
	
	@Input ()
	classSelect: string = '';
	
	groupUserListValue: any;
	@Output ()
	groupUserListChange = new EventEmitter<string> ();
	
	@Input ()
	get groupUserList () {
		return this.groupUserListValue;
	}
	
	set groupUserList (val) {
		this.groupUserListValue = val;
		this.getGroupUserName (val);
		this.groupUserListChange.emit (this.groupUserListValue);
	}
	
	@Output ()
	selectItemChange = new EventEmitter<string> ();
	
	listGroupUserName: any = [];
	agentId: any;
	groupParentId: any = 0;
	constructor (
		private groupUserService: GroupUserService,
		private authenticationService: AuthenticationService
	) {
	
	}
	
	ngOnInit () {
		this.agentId = this.authenticationService.getAgentId ();
		if(LIST_GROUP_BY_AGENT[this.agentId]) {
			this.groupParentId = LIST_GROUP_BY_AGENT[this.agentId];
			this.getListGroupUser();
		}
	}
	
	getListGroupUser () {
		this.groupUserService.getGroupByParentId (this.groupParentId)
			.subscribe (
				res => {
					if (res.status === 'success') {
						this.groupUserList = res.data;
						this.getGroupUserName (this.groupUserList);
					} else {
						this.groupUserList = {};
						this.getGroupUserName (this.groupUserList);
					}
				}, (error) => {
					this.groupUserList = {};
					this.getGroupUserName (this.groupUserList);
				})
	}
	
	getGroupUserName (groupUserList) {
		let groupUserName = [];
		if (groupUserList && Object.keys (groupUserList).length > 0) {
			Object.keys (groupUserList).forEach ((key) => {
				groupUserName.push ({
					id   : key,
					name : groupUserList[key]
				});
			});
		}
		this.listGroupUserName = groupUserName;
	}
	
	selectItem (event) {
		this.selectItemChange.emit (event);
	}

}
