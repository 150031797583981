import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ClientAuthGuard } from "../../core/guards/client-auth.guard";

import { LoginComponent } from './login/login.component';
import { LoginAdminComponent } from "./login-admin/login-admin.component";
import { CreateAgentCodeComponent } from "./create-agent-code/create-agent-code.component";

import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { ChangePasswordComponent } from './change-password/change-password.component';

import { NotFoundComponent } from './not-found/not-found.component';
import { ForbiddenComponent } from './forbidden/forbidden.component';

import { ProfileComponent } from "./profile/profile/profile.component";
import { UserComponent } from "./users/user/user.component";

const routes: Routes = [
	{
		path      : 'login',
		component : LoginComponent,
		data      : {title : 'Log In'}
	},
	{
		path      : 'login-admin/:route/:agent_id/:user_id',
		component : LoginAdminComponent,
		data      : {title : 'Log In Admin'}
	},
	{
		path      : 'create-agent-code',
		component : CreateAgentCodeComponent,
		data      : {title : 'Log In'}
	},
	{
		path      : 'forgot-password',
		component : ForgotPasswordComponent,
		data      : {title : 'Forgot Password'}
	},
	{
		path      : 'reset-password/:token',
		component : ResetPasswordComponent,
		data      : {title : 'Reset password'}
  },
  {
		path      : 'change-password',
		component : ChangePasswordComponent,
		data      : {title : 'edit password'}
	},
	{
		path      : '404',
		component : NotFoundComponent,
		data      : {title : '404'}
	},
	{
		path      : '403',
		component : ForbiddenComponent,
		data      : {title : '404'}
	},
	{
		path        : 'profile',
		component   : ProfileComponent,
		canActivate : [ClientAuthGuard],
		data        : {title : 'Tài Khoản thanh toán '}
	},
	{
		path        : 'profile/:type',
		component   : ProfileComponent,
		canActivate : [ClientAuthGuard],
		data        : {title : 'Tài Khoản thanh toán '}
	},
	{
		path        : 'list-user',
		component   : UserComponent,
		canActivate : [ClientAuthGuard],
		data        : {title : 'Danh sách nhân viên đại lý'}
	},
];

@NgModule ({
	imports : [RouterModule.forChild (routes)],
	exports : [RouterModule],
})
export class AuthRoutingModule {
	static components = [
		LoginComponent,
		ForgotPasswordComponent,
		NotFoundComponent,
		ResetPasswordComponent,
		ForbiddenComponent
	];

}
