export const environment = {
	production         : false,
	host_dowload       : 'https://api.dev.monkeyuni.net/',
	host               : 'https://api.dev.monkeyuni.net/api/',
	host_crm           : 'https://crm.dev.monkeyuni.net/api/',
	host_auth_download : 'https://auth.dev.monkeyuni.com/',
	host_auth          : 'https://auth.dev.monkeyuni.com/api/',
	host_mail          : 'https://email.dev.monkeyuni.com/api/',
	host_agent         : 'https://agent.dev.monkeyuni.net/api/',
	host_media         : 'https://media.dev.monkeyuni.net/api/',
	google_client_id   : '794463747839-s2c9mvrkreignl677mvcibvgikto4v09.apps.googleusercontent.com'
};
