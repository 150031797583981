import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from "@angular/common/http";
import { Observable } from "rxjs";
import { environment } from "../../../../../environments/environment";

@Injectable ({
	providedIn : 'root'
})
export class BankBranchService {
	
	constructor (private http: HttpClient) {
	}
	
	getListBankBranchByBank (bankId: any): Observable<any> {
		const url  = environment.host_agent + 'get-list-bank-branch-by-bank';
		let params = new HttpParams ();
		if (bankId) {
			params = params.append ('bank_id', bankId.toString ());
		}
		return this.http.get<any> (url, {params : params});
	}
	
	
}
