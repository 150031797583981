import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormControl} from '@angular/forms';
import {ToasterService} from 'angular2-toaster';
import {appToaster} from '../../../configs/app-toaster.config';
import {TranslateService} from '@ngx-translate/core';
import {DocumentService} from "../../../core/http/agent/document/document.service";

@Component({
    selector   : 'app-doc-category-select',
    templateUrl: './doc-category-select.component.html',
    styleUrls  : ['./doc-category-select.component.css']
})
export class DocCategorySelectComponent implements OnInit {

    @Input('parentForm')
    public parentForm: FormControl;

    @Input('placeholder')
    public placeholder: string = this.translateService.instant('select_category');

    @Input() multiple: boolean = false;

    @Input() clearable: boolean = true;

    @Input() selectParent: any = null;

    typeUpdateValue: boolean = true;
    @Output()
    typeUpdateChange         = new EventEmitter<any>();

    @Input()
    get typeUpdate() {
        return this.typeUpdateValue;
    }

    set typeUpdate(val) {
        this.typeUpdateValue = val;
        this.typeUpdateChange.emit(this.typeUpdateValue);
    }

    countryCodeValue: string;

    @Input()
    get country() {
        return this.countryCodeValue;
    }

    set country(val) {
        this.getListCategoryName(val);
        this.countryCodeValue = val;
    }

    @Input()
    classSelect: string = '';

    categoryListValue: any;
    @Output()
    categoryListChange = new EventEmitter<string>();

    @Input()
    get categoryList() {
        return this.categoryListValue;
    }

    set categoryList(val) {
        this.categoryListValue = val;
        this.factoryListCategoryName(val);
        this.categoryListChange.emit(this.categoryListValue);
    }

    categoryNameList = [];

    @Output()
    selectItemChange = new EventEmitter<string>();

    constructor(private documentService: DocumentService,
                private  toasterService: ToasterService,
                private  translateService: TranslateService
    ) {
    }

    ngOnInit() {
    }

    getListCategoryName(country) {
        if (country) {
            if (!this.typeUpdate) {
                this.parentForm.setValue(null);
            }
            this.typeUpdateValue = false;
            this.documentService.getListDocCategoryName(country, this.selectParent)
            .subscribe((res) => {
                if (res.status == 'success') {
                    this.categoryList = res.data;
                    this.factoryListCategoryName(this.categoryList);
                } else {
                    this.categoryNameList = [];
                    this.toasterService.pop('error', appToaster.errorHead, res.message);

                }
            }, (error) => {
                this.categoryNameList = [];

            });
        } else {
            this.parentForm.setValue(null);
            this.categoryNameList = [];
        }
    }

    factoryListCategoryName(data) {
        let arrListCategory = [];
        if (this.selectParent == 0) {
            data.forEach((element) => {
                arrListCategory.push({parent: null, key: element['id'], name: element['name'],});
            })
        } else {
            Object.keys(data).forEach(key => {
                data[key].forEach((element) => {
                    if (this.selectParent) {
                        if (parseInt(key) == 0) {
                            key = 'All';
                            arrListCategory.push({parent: key, key: element['id'], name: element['name'],});
                        }
                    } else {
                        if (parseInt(key) != 0) {
                            arrListCategory.push({parent: key, key: element['id'], name: element['name'],});
                        }
                    }

                });
            });
        }
        this.categoryNameList = arrListCategory;
    }

    selectItem(event) {
        this.selectItemChange.emit(event);
    }

}
