import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl } from "@angular/forms";
import { ToasterService } from "angular2-toaster";
import { appToaster } from "../../../configs/app-toaster.config";

import { ListsPostService } from "../../../core/http/delivery/lists-post/lists-post.service";


@Component ({
  selector    : 'app-city-select',
  templateUrl : './city-select.component.html',
  styleUrls   : ['./city-select.component.css']
})
export class CitySelectComponent implements OnInit {
  @Input ('parentForm')
  public parentForm: FormControl;

  @Input () multiple: boolean = false;

  @Input () clearable: boolean = true;

  @Input ()
  classSelect: string = '';

  cityListValue: any;
  @Output ()
  cityListChange = new EventEmitter<any> ();

  typeUpdateValue: boolean = true;
  @Output ()
  typeUpdateChange         = new EventEmitter<any> ();

  @Input ()
  get typeUpdate () {
    return this.typeUpdateValue;
  }

  set typeUpdate (val) {
    this.typeUpdateValue = val;
    this.typeUpdateChange.emit (this.typeUpdateValue);
  }

  @Input ()
  get cityList () {
    return this.cityListValue;
  }

  set cityList (val) {
    this.cityListValue = val;
    this.factoryListCityName (val);
    this.cityListChange.emit (this.cityListValue);
  }
  cityNameList: any = [];
  countryCodeValue: string;

  @Input ()
  get countryCode () {
    return this.countryCodeValue;
  }

  set countryCode (val) {
    this.getCityByCountryCode (val);
    this.countryCodeValue = val;
  }

  @Output ()
  selectItemChange = new EventEmitter<string> ();

  constructor (private listsPostService: ListsPostService,
               private toasterService: ToasterService) {

  }

  ngOnInit () {
  }

  getCityByCountryCode (countryCode) {
    if (countryCode) {
      if (!this.typeUpdate) {
        this.parentForm.setValue (null);
      }
      this.typeUpdateValue = false;
      this.listsPostService.getListCityByParentIdAndCountryCode (countryCode, 0)
        .subscribe ((res) => {
          if (res.status == "success") {
            this.cityList = res.data;
            this.factoryListCityName (this.cityList);
          } else {
            this.cityList = {};
            this.factoryListCityName (this.cityList);
            this.toasterService.pop ('error', appToaster.errorHead, res.message);

          }
        }, (error) => {
          this.cityList = {};
          this.factoryListCityName (this.cityList);
        });

    } else {
      this.parentForm.setValue (null);
      this.cityList = {};
      this.factoryListCityName (this.cityList);
    }
  }

  factoryListCityName (data) {
    let arrListCity = [];

    Object.keys (data).forEach (key => {
      arrListCity.push ({id : parseInt(key), name : data[key]});
    });
    this.cityNameList = arrListCity;
  }

  selectItem (event) {
    this.selectItemChange.emit (event);
  }
}
