import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from "@angular/router";
import { AuthenticationService } from "../../../core/authentication/authentication.service";

@Component ({
	selector    : 'app-login-admin',
	templateUrl : './login-admin.component.html',
	styleUrls   : ['./login-admin.component.css']
})
export class LoginAdminComponent implements OnInit {
	
	agent_id: string;
	user_id: string;
	route: string;
	listRoute: any = ['agent/dashbroad-hold-card', 'agent/dashbroad-deposit', 'agent/dashbroad-affi'];
	
	constructor (
		private activatedRoute: ActivatedRoute,
		private authenticationService: AuthenticationService,
		private router: Router
	) {
	}
	
	ngOnInit () {
		this.activatedRoute.params.subscribe (params => {
			this.route    = params['route'];
			this.user_id  = params['user_id'];
			this.agent_id = params['agent_id'];
			this.user_id  = atob(this.user_id);
			this.authenticationService.loginAdmin (this.agent_id, this.user_id)
				.subscribe ((res) => {
					if (this.route == 'dashboard') {
						let i = 0;
						Object.keys (res.data.listRouterView).forEach ((key) => {
							if (i == 0) {
								let listRoute = res.data.listRouterView[key]['router'];
								Object.keys (listRoute).forEach ((key) => {
									if(this.listRoute.indexOf(key) != -1)
									{
										this.route = key;
									}
								});
								i = i + 1;
							}
						})
					}
					else
					{
						this.route = 'agent/' + this.route;
					}
					this.router.navigate ([this.route]);
				});
		});
	}
}
