import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl } from "@angular/forms";
import { GroupUserService } from "../../../core/http/global/group-user/group-user.service";

@Component({
  selector: 'app-user-select',
  templateUrl: './user-select.component.html',
  styleUrls: ['./user-select.component.css']
})
export class UserSelectComponent implements OnInit {
	
	@Input ('parentForm')
	public parentForm: FormControl;
	@Input () removeValue: boolean = true;
	
	typeUpdateValue: boolean = true;
	@Output ()
	typeUpdateChange         = new EventEmitter<any> ();
	
	@Input ()
	get typeUpdate () {
		return this.typeUpdateValue;
	}
	
	set typeUpdate (val) {
		this.typeUpdateValue = val;
		this.typeUpdateChange.emit (this.typeUpdateValue);
	}
	
	groupIdValue: string;
	
	@Input ()
	get groupId () {
		return this.groupIdValue;
	}
	
	set groupId (val) {
		if (this.typeUpdate && this.removeValue) {
			this.parentForm.setValue (null);
		} else {
			this.typeUpdateValue = true;
		}
		this.getListUser (val);
		this.groupIdValue = val;
	}
	
	@Input () multiple: boolean = false;
	
	@Input ()
	classSelect: string = '';
	
	userListValue: any;
	@Output ()
	userListChange = new EventEmitter<string> ();
	
	@Input ()
	get userList () {
		return this.userListValue;
	}
	
	set userList (val) {
		this.userListValue = val;
		this.userListChange.emit (this.userListValue);
	}
	
	@Output ()
	selectItemChange = new EventEmitter<string> ();
	
	constructor (private groupUserService: GroupUserService) {
	
	}
	
	ngOnInit () {
	}
	
	getListUser (groupId) {
		if (groupId) {
			this.groupUserService.getUserByGroupId (groupId)
				.subscribe (
					res => {
						if (res.status === 'success') {
							this.userList = res.data;
						} else {
							this.userList = {};
						}
					}, (error) => {
						this.userList = {};
					})
		} else {
			this.userList = {};
		}
	}
	
	selectItem (event) {
		this.selectItemChange.emit (event);
	}
}
