import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from "@angular/common/http";
import { Observable } from "rxjs";
import { environment } from "../../../../../environments/environment";
import { HistorySearchModel } from "../../../../shared/models/agent/history-search.model";

@Injectable({
    providedIn: 'root'
})
export class NotificationService {

    constructor(private http: HttpClient) {}

    getListLogsAction(historySearchModel: HistorySearchModel): Observable<any> {
        const url = environment.host_agent + 'get-list-logs-action';
        let params = new HttpParams();
        params = params.append('page', historySearchModel.page.toString());
        params = params.append('limit', historySearchModel.limit.toString());
        params = params.append('isAgentAdmin', historySearchModel.agentAdmin.toString());

        return this.http.get<any>(url, {params: params});
    }

    updateStatusNotification(id: any): Observable<any> {
        const url = environment.host_agent + 'update-status-seen-notification';
        return this.http.post<any>(url, {id: id});
    }

    updateAllStatusNotification(isAgentAdmin): Observable<any> {
        let isAdmin = 0;
        if (isAgentAdmin) {
            isAdmin = 1;
        }
        const url = environment.host_agent + 'update-all-status-notification';
        return this.http.post(url, {isAgentAdmin: isAdmin});
    }
}
