import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AgentService } from "../../../core/http/agent/agent/agent.service";
import { appToaster } from "../../../configs/app-toaster.config";
import { ToasterService } from "angular2-toaster";
import { FormControl } from "@angular/forms";

@Component ({
	selector    : 'app-group-select',
	templateUrl : './group-select.component.html',
	styleUrls   : ['./group-select.component.css']
})
export class GroupSelectComponent implements OnInit {
	
	
	@Input ('parentForm')
	public parentForm: FormControl;
	
	@Input () multiple: boolean = false;
	
	listGroupAgentName : any = [];
	
	listGroupAgentValue: any = {};
	
	@Output ()
	listGroupAgentChange = new EventEmitter<any> ();
	
	@Input ()
	get listGroupAgent () {
		return this.listGroupAgentValue;
	}
	
	set listGroupAgent (val) {
		this.listGroupAgentValue = val;
		if (val) {
			this.convertGroupAgentName (val);
		}
		this.listGroupAgentChange.emit (this.listGroupAgentValue);
	}
	
	constructor (
		private agentService: AgentService,
		private toasterService: ToasterService
	) {
	}
	
	ngOnInit () {
		if (Object.keys (this.listGroupAgent).length == 0) {
			this.getAllGroupAgent ();
		}
	}
	
	getAllGroupAgent () {
		this.agentService.getAllGroup ()
			.subscribe ((res) => {
				if (res.status === 'success') {
					this.listGroupAgent = res.data;
				} else {
					this.toasterService.pop ('error', appToaster.errorHead, res.message);
				}
			});
	}
	
	convertGroupAgentName (val) {
		let listName = [];
		Object.keys (val).forEach ((key) => {
			listName.push({
				id: val[key]['id'],
				name: val[key]['name']
			})
		});
		this.listGroupAgentName = listName;
	}
	
}
