import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'convertSerial' })
export class ConvertSerialPipe implements PipeTransform {
	transform (serial: string): any {
		if(serial) {
			serial = serial.toString ();
			let $serialFirst= serial.slice(0, 11);
			let $serialSecond= serial.slice(11, 15);
			$serialFirst = $serialFirst.replace (/1|2|3|4|5|6|7|8|9|0/g, "*");
			return $serialFirst + $serialSecond;
		}
		return  serial;
	}
	
}
