import { Directive, HostListener } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive ({
	selector : '[appPhoneMask]',
})
export class PhoneMaskDirective {
	
	constructor (public ngControl: NgControl) {
	}
	
	@HostListener ('ngModelChange', ['$event'])
	onModelChange (event) {
		this.onInputChange (event, false);
	}
	
	@HostListener ('keydown.backspace', ['$event'])
	keydownBackspace (event) {
		this.onInputChange (event.target.value, true);
	}
	
	
	onInputChange (event, backspace) {
		let newVal = event.replace (/\D/g, '');
		if (backspace && newVal.length <= 6) {
			newVal = newVal.substring (0, newVal.length - 1);
		}
		if (newVal.length === 0) {
			newVal = '';
		} else {
			let firstNumbers = newVal.substring (0, 1);
			if (firstNumbers != 0) {
				newVal = newVal.replace (/^(\d{0,11})/, '0$1');
			}
			newVal = newVal.substring (0, 11);
		}
		this.ngControl.valueAccessor.writeValue (newVal);
	}
}
