import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { ToasterService } from "angular2-toaster";
import { LogValidationErrorsService } from "../../../../core/services/log-validation-errors.service";
import { appToaster } from "../../../../configs/app-toaster.config";

import { UserAgentService } from "../../../../core/http/agent/user-agent/user-agent.service";

import { User } from "../../../../shared/models/auth/user.model";
import { EMAIL_REGEX, PASSWOR_REGEX } from "../../../../shared/models/base.model";

@Component ({
	selector    : 'app-edit-user',
	templateUrl : './edit-user.component.html',
	styleUrls   : ['./edit-user.component.css']
})
export class EditUserComponent implements OnInit {
	
	formErrors = {
		'fullname'        : '',
		'email'           : '',
		'password'        : '',
		'confirmPassword' : '',
		'role'            : '',
	};
	
	validationMessages = {
		'fullname'        : {
			'required'  : 'Fullname  is required.',
			'minlength' : 'Fullname at least 4 characters.',
			'maxlength' : 'Fullname  must be less than 200 characters.',
		},
		'email'           : {
			'required'  : 'Email is required.',
			'pattern'   : 'Email validators format.',
			'minlength' : 'Email at least 4 characters.',
			'maxlength' : 'Email must be less than 200 characters.',
		},
		'password'        : {
			'required'  : 'Password is required.',
			'pattern'   : 'Password validators format. (password không có dấu cách và không có dấu)',
			'minlength' : 'Password at least 4 characters.',
			'maxlength' : 'Password must be less than 20 characters.',
		},
		'confirmPassword' : {
			'required'  : 'ConfirmPassword is required.',
			'pattern'   : 'ConfirmPassword validators format. (password không có dấu cách và không có dấu)',
			'minlength' : 'ConfirmPassword at least 4 characters.',
			'maxlength' : 'ConfirmPassword must be less than 20 characters.',
		},
		'role'            : {}
	};
	
	
	@Input () modalHeader: string;
	@Input () listRole: any = {};
	@Input () user: any;
	@Input () agentId: any;
	
	loadingSave: boolean = false;
	
	userForm: FormGroup;
	roleForm: FormGroup = this.fb.group ({});
	
	constructor (
		private fb: FormBuilder,
		private activeModal: NgbActiveModal,
		private toasterService: ToasterService,
		private userAgentService: UserAgentService,
		private logValidationErrorsService: LogValidationErrorsService
	) {
	}
	
	ngOnInit () {
		if (Object.keys (this.listRole).length == 0) {
			this.getListRole ();
		} else {
			this.getListRoleView ();
		}
		this.createUserForm ();
	}
	
	getListRole () {
		this.userAgentService.getListRoleAgent ()
			.subscribe (
				res => {
					if (res.status === 'success') {
						this.listRole = res.data;
						this.getListRoleView ();
					} else {
						this.listRole = {};
						this.getListRoleView ();
						this.toasterService.pop ('error', appToaster.errorHead, res.message);
					}
				}, (error) => {
					this.listRole = {};
					this.getListRoleView ();
				})
	}
	
	getListRoleView () {
		let roleForm = this.fb.group ({});
		let userRole = {};
		
		if (this.user) {
			Object.keys (this.user.user_role).forEach ((key) => {
				let role_id       = this.user.user_role[key]['role_id'];
				userRole[role_id] = role_id;
			});
		}
		
		Object.keys (this.listRole).forEach (function (key) {
			let value = false;
			if (userRole[key]) {
				value = true;
			}
			roleForm.addControl (key, new FormControl (value));
		});
		this.roleForm = roleForm;
		
	}
	
	createUserForm () {
		this.userForm = this.fb.group ({
			fullname : [
				'', [
					Validators.required,
					Validators.minLength (4),
					Validators.maxLength (200),
				]
			],
			email    : [
				'', [
					Validators.required,
					Validators.minLength (4),
					Validators.maxLength (200),
					Validators.pattern (EMAIL_REGEX)
				]
			],
			role     : this.roleForm
		});
		if (!this.user) {
			this.userForm.addControl ('password', new FormControl ('',
				[
					Validators.required,
					Validators.minLength (4),
					Validators.maxLength (20),
					Validators.pattern (PASSWOR_REGEX)
				]));
			this.userForm.addControl ('confirmPassword', new FormControl ('',
				[
					Validators.required,
					Validators.minLength (4),
					Validators.maxLength (20),
					Validators.pattern (PASSWOR_REGEX)
				]));
		} else {
			this.userForm.get ('fullname').setValue (this.user.fullname);
			this.userForm.get ('email').setValue (this.user.email);
			this.userForm.get('email').disable();
		}
		
		this.userForm.valueChanges.subscribe ((data) => {
			this.logValidationErrorsService.logValidationErrors (this.userForm, this.formErrors, this.validationMessages);
		});
	}
	
	editUserAgent () {
		if (!this.userForm.valid) {
			return this.logValidationErrorsService.logValidationErrors (this.userForm, this.formErrors, this.validationMessages);
		}
		
		if (!this.agentId) {
			return this.toasterService.pop ('error', appToaster.errorHead, "lỗi than số");
		}
		
		if (!this.user && this.userForm.get ('password').value != this.userForm.get ('confirmPassword').value) {
			this.toasterService.pop ('error', appToaster.errorHead, "Nhập lại mật khẩu không chính xác");
		}
		
		let userModel: User = new User ();
		if (this.user) {
			if (!this.user.id) {
				return this.toasterService.pop ('error', appToaster.errorHead, "lỗi than số");
			}
			userModel.id = this.user.id;
		} else {
			userModel.password = this.userForm.get ('password').value;
		}
		
		userModel.agent_id = this.agentId;
		userModel.fullname = this.userForm.get ('fullname').value;
		userModel.email    = this.userForm.get ('email').value;
		userModel.role     = this.userForm.get ('role').value;
		
		let checkRole = false;
		Object.keys(userModel.role).forEach ((key) => {
			if(userModel.role[key]) {
				checkRole = true;
			}
		});
		
		if(checkRole == false) {
			return this.toasterService.pop ('error', appToaster.errorHead, "Chưa chọn quyền");
		}
		
		this.loadingSave    = true;
		this.userAgentService.editUserAgent (userModel)
			.subscribe ((res) => {
				this.loadingSave = false;
				if (res.status === 'success') {
					this.toasterService.pop ('success', appToaster.successHead, res.message);
					this.activeModal.close (true);
				} else {
					this.toasterService.pop ('error', appToaster.errorHead, res.message);
				}
			}, (error) => {
				this.loadingSave = false;
			});
	}
	
	close (): void {
		this.activeModal.close (false);
	}
	
}
