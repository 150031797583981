import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from "@angular/common/http";
import { Observable } from "rxjs";
import { environment } from "../../../../../environments/environment";

@Injectable ({
	providedIn : 'root'
})
export class GroupUserService {
	constructor (private http: HttpClient) {
	}
	
	getUserByGroupId (group_id): Observable<any> {
		const url  = environment.host_auth + 'get-user-by-group-id';
		let params = new HttpParams ();
		params     = params.append ('group_id', group_id.toString ());
		return this.http.get<any> (url, {params : params});
	}
	
	getGroupByParentId (parent_id): Observable<any> {
		const url  = environment.host_auth + 'get-group-by-parent-id';
		let params = new HttpParams ();
		params     = params.append ('parent_id', parent_id.toString ());
		return this.http.get<any> (url, {params : params});
	}
}
