import { Injectable } from '@angular/core';
import { Observable } from "rxjs";
import { HttpClient, HttpParams } from "@angular/common/http";
import { environment } from "src/environments/environment";

@Injectable ({
	providedIn : 'root'
})
export class FolderService {

	constructor (private http: HttpClient) {
	}

	getListFoldersByFolder (folder_path: string): Observable<any> {
		const url  = environment.host_media + "get-list-folders";
		let params = new HttpParams ();
		params     = params.append ('parent_folder_path', folder_path);
		return this.http.get<any> (url, {params : params});
	}

	createFolder (parent_folder_path: string, new_folder_name: string): Observable<any> {
		const url = environment.host_media + "create-folder";
		return this.http.post<any> (url, {'parent_folder_path' : parent_folder_path, 'new_folder_name' : new_folder_name});
	}

	deleteFolder (folder_path: string): Observable<any> {
		const url = environment.host_media + "delete-folder";
		return this.http.post<any> (url, {'folder_path' : folder_path});
	}

	getPermission (role_id: number, folder_id: number): Observable<any> {
		const url  = environment.host_media + "get-permission-folder";
		let params = new HttpParams ();
		params     = params.append ('role_id', String (role_id));
		params     = params.append ('folder_id', String (folder_id));
		return this.http.get<any> (url, {params : params});
	}

	setPermission (role_id: number, folder_id: number, change_val: number): Observable<any> {
		const url = environment.host_media + "set-permission-folder";
		return this.http.post<any> (url, {'role_id' : role_id, 'folder_id' : folder_id, 'change_val' : change_val});
	}

	getFolderByPath (folder_path: string): Observable<any> {
		const url  = environment.host_media + "get-folder-by-path";
		let params = new HttpParams ();
		params     = params.append ('folder_path', folder_path);
		return this.http.get<any> (url, {params : params});
	}

	createRootFolder (): Observable<any> {
		const url = environment.host_media + "create-root-folder";
		return this.http.post<any> (url, {});
	}
}
