import { Component, Input, OnInit } from '@angular/core';
import { FormControl } from "@angular/forms";

@Component ({
	selector    : 'app-status-select',
	templateUrl : './status-select.component.html',
	styleUrls   : ['./status-select.component.css']
})
export class StatusSelectComponent implements OnInit {

	@Input ('parentForm')
	public parentForm: FormControl;

	@Input () multiple: boolean = false;

	@Input () statusList: any;

	constructor () {
	}

	ngOnInit () {

	}

}
