import { Injectable } from '@angular/core';
import { Observable } from "rxjs";
import { environment } from "../../../../../environments/environment";
import { HttpClient, HttpParams } from "@angular/common/http";

@Injectable ({
	providedIn : 'root'
})
export class ListsPostService {

	constructor (private http: HttpClient) {
	}

	getListCityByParentId (parent_id: number): Observable<any> {
		const url  = environment.host_crm + 'list-city-by-parent-id';
		let params = new HttpParams ();

		if (parent_id) {
			params = params.append ('parent_id', parent_id.toString ());
		}

		return this.http.get<any> (url, {params : params});
	}

  getListCityByParentIdAndCountryCode (country_code: string, parent_id: number, full = false): Observable<any> {
    const url  = environment.host_crm + 'get-list-city-by-parent-id-and-country-code';
    let params = new HttpParams ();
    if (country_code) {
      params = params.append ('country_code', country_code);
    }
    if (parent_id) {
      params = params.append ('parent_id', parent_id.toString ());
    }
    if (full) {
      params = params.append ('full', full.toString());
    }
    return this.http.get<any> (url, {params : params});
  }

}
