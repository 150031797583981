import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup } from "@angular/forms";
import { ProjectService } from "../../../core/http/global/project/project.service";
import { appToaster } from "../../../configs/app-toaster.config";

@Component ({
	selector    : 'app-project-select',
	templateUrl : './project-select.component.html',
	styleUrls   : ['./project-select.component.css']
})
export class ProjectSelectComponent implements OnInit {

	@Input ('parentForm')
	public parentForm: FormControl;

	@Input () multiple: boolean = false;

	@Input () disabled: boolean = false;

	@Input () clearable: boolean = true;

	@Input() checkGetData: boolean = true;

	@Input ()
	classSelect: string = '';

	projectListValue: any;
	@Output ()
	projectListChange = new EventEmitter<string> ();

	@Input ()
	get projectList () {
		return this.projectListValue;
	}

	set projectList (val) {
		this.projectListValue = val;
		this.getProjectName (val);
		this.projectListChange.emit (this.projectListValue);
	}

	@Output ()
	selectItemChange = new EventEmitter<string> ();

	listProjectName: any = [];

	constructor (private projectService: ProjectService) {

	}

	ngOnInit () {
		if (this.checkGetData && Object.keys (this.projectList).length == 0) {
			this.getListProject ();
		}
	}

	getListProject () {
		this.projectService.getProjectList ()
			.subscribe (
				res => {
					if (res.status === 'success') {
						this.projectList = res.data;
						this.getProjectName (this.projectList);
					} else {
						this.projectList = {};
						this.getProjectName (this.projectList);
					}
				}, (error) => {
					this.projectList = {};
					this.getProjectName (this.projectList);
				})
	}

	getProjectName (projectList) {
		let projectName = [];
		if (projectList && Object.keys (projectList).length > 0) {
			Object.keys (projectList).forEach ((key) => {
				projectName.push ({
					id   : key,
					name : projectList[key]
				});
			});
		}
		this.listProjectName = projectName;
	}

	selectItem (event) {
		this.selectItemChange.emit (event);
	}
}
