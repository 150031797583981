import { Directive, ElementRef, HostListener, OnInit } from '@angular/core';
import { NgControl } from "@angular/forms";

@Directive ({
	selector : '[appUpperCase]',
})
export class UpperCaseDirective implements OnInit {
	
	constructor (
		private ref: ElementRef,
		private ngControl: NgControl
	) {
	}
	
	ngOnInit () {
		let newVal = JSON.parse (JSON.stringify (this.ngControl.control.value));
		
		if (newVal) {
			newVal     = this.upperCase (newVal);
		}
		this.ref.nativeElement.value = newVal;
		setTimeout (() => {
			this.ngControl.control.setValue (newVal);
		}, 0);
	}
	
	@HostListener ('input', ['$event'])
	onInput (event) {
		const initalValue            = this.ref.nativeElement.value;
		let newVal                   = initalValue.toUpperCase ();
		newVal                       = this.upperCase (newVal);
		this.ref.nativeElement.value = newVal;
		if (initalValue !== this.ref.nativeElement.value) {
			event.stopPropagation ();
		}
		this.ngControl.control.setValue (newVal);
	}
	
	upperCase (newVal) {
		newVal = newVal.toUpperCase ();
		newVal = newVal.replace (/À|Á|Ạ|Ả|Ã|Â|Ầ|Ấ|Ậ|Ẩ|Ẫ|Ă|Ằ|Ắ|Ặ|Ẳ|Ẵ/g, "A");
		newVal = newVal.replace (/È|É|Ẹ|Ẻ|Ẽ|Ê|Ề|Ế|Ệ|Ể|Ễ/g, "E");
		newVal = newVal.replace (/Ì|Í|Ị|Ỉ|Ĩ/g, "I");
		newVal = newVal.replace (/Ò|Ó|Ọ|Ỏ|Õ|Ô|Ồ|Ố|Ộ|Ổ|Ỗ|Ơ|Ờ|Ớ|Ợ|Ở|Ỡ/g, "O");
		newVal = newVal.replace (/Ù|Ú|Ụ|Ủ|Ũ|Ư|Ừ|Ứ|Ự|Ử|Ữ/g, "U");
		newVal = newVal.replace (/Ỳ|Ý|Ỵ|Ỷ|Ỹ/g, "Y");
		newVal = newVal.replace (/Đ/g, "D");
		newVal = newVal.replace (/!|`|@|%|\^|\*|\(|\)|\+|=|<|>|\?|\/|,|\.|:|;|'|"|&|#|\[|]|~|\$|_/g, "");
		return newVal;
	}
	
}
